import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    expertiseData: {},
    loading: false,
    error: false,
}

export const ExpertiseSlice = createSlice({
    name: 'expertiseData',
    initialState,
    reducers: {

      expertiseDataFetchStart: (state) => {
        state.loading = true;
        state.error = false;
        state.expertiseData = {};
      },
      expertiseDataFetchSuccess: (state, action) => {
        state.loading = false;
        state.error = false;
        state.expertiseData = action.payload;
      },
      expertiseDataFetchFailure: (state) => {
        state.loading = false;
        state.error = true;
        state.expertiseData = {};
      }

    },
  })

export const { expertiseDataFetchStart, expertiseDataFetchSuccess, expertiseDataFetchFailure } = ExpertiseSlice.actions;

export default ExpertiseSlice.reducer;
