import './pleading.css';

import PleadingCpnt from '../../components/pleading/pleading';

import axios from 'axios';
import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';
import { LineWave } from  'react-loader-spinner';
import { Link, useLocation } from "react-router-dom";
import { useSelector } from 'react-redux';

export default function Pleading() {

	const queryParams = new URLSearchParams(window.location.search);

	const pleader = queryParams.get('pleader');

    const [search_term, setSearchTerm] = useState("");
    const [load, setLoader] = useState('');

    const loggedUser = useSelector(state=>state.user.user);

    const userSearch = async(e) => {
        e.preventDefault();

        setLoader(true);

        try{
            setSearchTerm("");
            setLoader(false);
        }
        catch(err) {
            setLoader(false);
            toast.error(err.response.data, { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast3' });
        }

    }

	return(
		<>
            <div className="top-section">.</div>
            <section aria-label="section">
                <div className="container">
                    <div className="row">

                        <div className="col-md-12 text-center">
                            <h2>Plaidoyers</h2>
                            <br/><br/>
                        </div>

                        <section className="text-light search-section">
                            <div className="container">
                                <div className="row align-items-center">
                                    <div className="col-md-8 mb-sm-30 text-lg-left text-sm-center">
                                        <input type="text" className="search-input" value={search_term} onChange={e=>setSearchTerm(e.target.value)} placeholder="Rechercher ..." />
                                    </div>

                                    {loggedUser && loggedUser.is_admin === true ? 

                                        <div className="col-md-4 text-lg-right text-sm-center">
                                            <Link to="/ajouter-un-plaidoyer" className="add-database">Ajouter un plaidoyer</Link>
                                        </div>

                                    : "" }
                                </div>
                            </div>
                        </section>
                        <div className="col-md-12">
                            <br/><br/>
                            <PleadingCpnt search_term={search_term} />
                        </div>
                    </div>
                </div>
            </section>

		</>
	)

}