import './pleading.css';

import axios from 'axios';
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { toast } from 'react-toastify';
import parse from 'html-react-parser';
import { TailSpin, RotatingLines } from  'react-loader-spinner';

export default function PleadingCpnt({ search_term }) {

	const [pleading_data, setPleadingData] = useState([]);
    const [loadPleading, setLoadPleading] = useState("true");

	useEffect(()=>{

        const getPleadings = async()=> {

            try{
                const pleadingsRes = await axios.get(process.env.REACT_APP_API_KEY+"/pleading/?search_term="+search_term);
                setPleadingData(pleadingsRes.data);

                setLoadPleading("false");
            }
            catch(err) {
                toast.error(err.response.data, { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast1' });
            }
        }

        // functions call 

        getPleadings();

    });

	return(

		<>
            {loadPleading === 'true' ? 

                <div className="data-loader">

                    <TailSpin 
                        height="40"
                        width="40"
                        color="#000"
                        ariaLabel="tail-spin-loading"
                        radius="1"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                    />

                </div>

                : "" 
            }

			{pleading_data?.map((pleading)=> (

                <div className="col-lg-4 col-md-4 col-sm-4 mb30 pull-left clear">
    				<div className="pleadings-list">

                        <div className="details">
                            <h4 className="pleading-details-h2">{pleading.title}</h4>
                            <p className="pleading-details-p">{parse(pleading.content.substring(0, 130)+ '...')}</p>
                            <Link className="view-button-more" to={{pathname: '/plaidoyer-activite/', search: '?param='+pleading._id}}>Voir le contenu</Link>
                        </div>

                    </div>
                </div>

            ))}
		</>
	)

}