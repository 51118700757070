import './news.css';

import News from '../../components/news/news';

import axios from 'axios';
import { Link, useLocation } from "react-router-dom";
import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';
import { LineWave } from  'react-loader-spinner';
import { useSelector } from 'react-redux';

export default function NewsPage() {

	const queryParams = new URLSearchParams(window.location.search);

	const domain = queryParams.get('domain');
	const experts = queryParams.get('expert');

    const [categories_data, setCategoriesData] = useState([]);
    const [search_term, setSearchTerm] = useState("");
    const [category_search, setCategorySearch] = useState("");
    const [load, setLoader] = useState('');

    const loggedUser = useSelector(state=>state.user.user);

    useEffect(()=>{

        const getCategoriesData = async()=>{
            const categoriesRes = await axios.get(process.env.REACT_APP_API_KEY+"/article-categories/");
            setCategoriesData(categoriesRes.data);
        }

        const getCategoryDetails = async(category_id)=>{
            const categoriesRes = await axios.get(process.env.REACT_APP_API_KEY+"/article-categories/"+category_id);
            setCategoriesData(categoriesRes.data);
        }

        // functions call 

        getCategoriesData();

    }, []);

    const filter = async(e) => {
        e.preventDefault();

        setLoader(true);

        try{
            setSearchTerm("");
            setCategorySearch("");
            setLoader(false);
        }
        catch(err) {
            setLoader(false);
            toast.error(err.response.data, { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast3' });
        }

    }

	return(
		<>
            <div className="top-section">.</div>
            <section aria-label="section" className="background">
                <div className="container">
                        
                        <section className="search-section text-light">
                            <div className="container">
                                <h4 className="filter-title">Filtrer</h4>
                                <div className="small-border"></div>
                                <br/>
                                <div className="row news-search-card align-items-center">
                                    <div className="col-md-4 mb-sm-30 padding-left-0 text-sm-center">
                                        <input type="text" className="search-field" value={search_term} onChange={e=>setSearchTerm(e.target.value)} placeholder="Rechercher ..." />
                                    </div>
                                    <div className="col-md-4 mb-sm-30 text-lg-left text-sm-center">
                                        <select className="news-search-input" value={category_search} onChange={e=>setCategorySearch(e.target.value)}>
                                            <option value=""> Choisir une catégories </option>
                                            {categories_data?.map((category)=>(
                                                <option value={category._id}> {category.category} </option>
                                            ))}                           
                                        </select>
                                    </div>

                                    {loggedUser && loggedUser.is_admin === true ? 

                                        <div className="col-md-4 text-lg-right text-sm-center">
                                            <Link to="/ajouter-article" className="add-database">Ajouter une article</Link>
                                        </div>

                                    : "" }
                                    
                                </div>
                            </div>
                        </section>

                    <div className="col-md-12">
                        <News search_term={search_term} category={category_search} limit={9} />
                    </div>
                </div>
            </section>

		</>
	)

}