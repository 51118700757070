import './expertises.css';

import axios from 'axios';
import parse from 'html-react-parser';
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { toast } from 'react-toastify';
import { LineWave } from  'react-loader-spinner';
import { useSelector } from 'react-redux';

export default function Expertise() {

    const queryParams = new URLSearchParams(window.location.search);
    const expertise_category = queryParams.get('category');

    const [load, setLoader] = useState('');
    const [search_term, setSearchTerm] = useState("");
    const [expertises_data, setExpertisesData] = useState([]);
    const [expertise_category_data, setExpertiseCategoryData] = useState([]);

    const loggedUser = useSelector(state=>state.user.user);

    useEffect(()=>{

        const loadExpertiseCategory = async()=> {

            try{
                const expertiseCategoryRes = await axios.get(process.env.REACT_APP_API_KEY+"/expertise-categories/"+expertise_category);
                setExpertiseCategoryData(expertiseCategoryRes.data);
            }
            catch(err) {
                toast.error(err.response.data, { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast1' });
            }
        }

        const loadExpertises = async()=> {

            try{
                const expertisesRes = await axios.get(process.env.REACT_APP_API_KEY+"/expertise/?category="+expertise_category);
                setExpertisesData(expertisesRes.data);
            }
            catch(err) {
                toast.error(err.response.data, { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast2' });
            }
        }

        // functions call 

        loadExpertiseCategory();
        loadExpertises();

    }, []);

    const search = async(e) => {
        e.preventDefault();

        setLoader(true);

        try{
            setSearchTerm("");
            setLoader(false);
        }
        catch(err) {
            setLoader(false);
            toast.error(err.response.data, { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast3' });
        }

    }

    return(
        
        <>
            <div className="top-section">.</div>

            {loggedUser && loggedUser.is_admin === true ? 

                <section className="pt40 pb40 text-light">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-8 mb-sm-30 text-lg-left text-sm-center">
                                {/*<input type="text" className="search-input" value={search_term} onChange={e=>setSearchTerm(e.target.value)} placeholder="Rechercher ..." />*/}
                            </div>
                            <div className="col-md-4 text-lg-right text-sm-center">
                                <Link to="/ajouter-domaine" className="add-database">Ajouter un domaine</Link>
                            </div>
                        </div>
                    </div>
                </section>

            : "" }

        	<section aria-label="section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h2>Expertises dans le domaine { expertise_category_data.category } <br/><br/></h2>
                        </div>

                        <div id="sidebar" className="col-md-3">
                            <div className="widget widget-post">
                                <h4>Filtrer</h4>
                                <div className="small-border"></div>
                                <br/>
                                <div className="user-search-card">
                                    <input type="text" value={search_term} onChange={e=>setSearchTerm(e.target.value)} placeholder="Rechercher ..." />
                                    <input type="text" placeholder="Lieu" />

                                    {load === true ?
                                        <a className="newsletter-registration-load">
                                            <LineWave 
                                                        height="100"
                                                        width="100"
                                                        color="#4fa94d"
                                                        ariaLabel="line-wave"
                                                        wrapperStyle={{}}
                                                        wrapperClass=""
                                                        visible={true}
                                                        firstLineColor=""
                                                        middleLineColor=""
                                                        lastLineColor=""
                                            />
                                        </a> : <button className="search-button" onClick={search}>Chercher</button>
                                    
                                    }

                                </div>
                            </div>
                        </div>

                        <div className="col-md-9">

                            {expertises_data?.map((expertise)=> (

                                <div className="col-lg-6 col-md-6 col-sm-6 mb30 pull-left clear">
                                    <div className="expertises-list">

                                        <div className="details">
                                            <h4 className="expertise-h2">{expertise.name}</h4>
                                            <p className="expertise-p">{parse(expertise.description.substring(0, 130)+ '...')}</p>
                                            <Link to={{pathname: '/domain-details/', search: '?expert=true&domain='+expertise._id}}><button>&nbsp; Voir &nbsp;&nbsp;<i class="fa fa-eye" aria-hidden="true"></i> &nbsp;</button></Link>
                                        </div>
                                        {/*<div className="view-button">
                                                                                    <Link className="voir-experts" to={{pathname: '/domain-details/', search: '?expert=true&domain='+expertise._id}}><button><i className="fa fa-arrow-right"></i></button></Link>
                                                                                </div>*/}

                                    </div>
                                </div>

                            ))}

                        </div>

                    </div>
                </div>
            </section>   
        </>

    )
}