import './header.css';

import axios from 'axios';
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';

import { loginFailure, loginStart, loginSuccess } from '../../redux/userSlice';
import { companyDataFetchStart, companyDataFetchSuccess, companyDataFetchFailure } from '../../redux/companySlice';
import { expertiseDataFetchStart, expertiseDataFetchSuccess, expertiseDataFetchFailure } from '../../redux/expertisesSlice.js';

export default function Header() {

    const dispatch = useDispatch();

    const [company_details, setCompanyData] = useState([]);
    const [law_categories_data, setLawCategoriesResData] = useState([]);

    const loggedUser = useSelector(state=>state.user.user);

    useEffect(()=>{

        const loadCompanyInitialState = async()=> {

            dispatch(companyDataFetchStart());

            try{
                const companyRes = await axios.get(process.env.REACT_APP_API_KEY+"/company/?host_organization=true");
                dispatch(companyDataFetchSuccess(companyRes.data));
                setCompanyData(companyRes.data);
            }
            catch(err) {
                dispatch(companyDataFetchFailure());
                toast.error(err.response.data, { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast1' });
            }
        }

        const getExpertises = async()=> {

            dispatch(expertiseDataFetchStart());

            try{
                const expertisesRes = await axios.get(process.env.REACT_APP_API_KEY+"/law-categories/");
                dispatch(expertiseDataFetchSuccess(expertisesRes.data));
                setLawCategoriesResData(expertisesRes.data);
            }
            catch(err) {
                dispatch(expertiseDataFetchFailure());
                toast.error(err.response.data, { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast1' });
            }
        }

        // functions call 

        loadCompanyInitialState();
        getExpertises();

    }, []);

    const logout = async()=> {

        try{
            const logoutRes = await axios.put(process.env.REACT_APP_API_KEY+"/auth/logout/", null, {
              headers: {
                Authorization: loggedUser.token
              }
            });

            dispatch(loginSuccess());

            toast.success(logoutRes.data.message, { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast1' });
        }
        catch(err) {
            toast.error(err.response.data, { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast1' });
        }

    }

    const [mobileMenuShow, setMobileMenuShow] = useState(0);

    const handleMenuBtnClick = () => {

        const headerElement = document.querySelector('header.header-mobile');
        const headerHeight = headerElement.scrollHeight;
        
        if (mobileMenuShow === 0) {
          headerElement.style.height = `${headerHeight}px`;
          setMobileMenuShow(1);
        } else {
          headerElement.style.height = '';
          setMobileMenuShow(0);
        }
    
    };

    return(
        
        <>            
            <div id="topbar" className="topbar-noborder">

                {Array.isArray(company_details) && company_details.map((comp) => (

                    <div className="container">
                        <div className="topbar-left sm-hide">
                            <span className="topbar-widget tb-social">
                                <a href="{comp.facebook_url}"><i className="fa fa-facebook"></i></a>
                                <a href="{comp.twitter_url}"><i className="fa fa-twitter"></i></a>
                                <a href="{comp.instagram_url}"><i className="fa fa-instagram"></i></a>
                            </span>
                        </div>
                        <div className="topbar-right">
                            <div className="topbar-right">
                                {loggedUser ?
                                    <>
                                        <span className="topbar-widget"><Link to={`/profile/?param=${loggedUser.slug}`}>Bonjour, { loggedUser.last_name }</Link></span> <span className="bar-separator">|</span> 
                                        <span className="topbar-widget"><Link onClick={logout} to="/">Se déconnecter</Link></span>
                                    </>
                                :
                                    <>
                                        <span className="topbar-widget"><Link to="/connection">Se connecter</Link></span> <span className="bar-separator">|</span> 
                                        <span className="topbar-widget"><Link to="/creer-un-compte">Créer un compte</Link></span>
                                    </>
                                } 
                            </div>
                        </div>
                        <div className="clearfix"></div>
                    </div>

                ))}

            </div>
            <header className="header-light">
                <div className="header_container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="de-flex sm-pt10">
                                <div className="de-flex-col">
                                    <div id="logo">
                                        <Link to="/">
                                            <img alt="" className="logo" src="images/logo-light.png" />
                                            <img alt="" className="logo-2" src="images/logo.png" />
                                        </Link>
                                    </div>
                                </div>
                                <div className="de-flex-col header-col-mid">
                                    <ul id="mainmenu">
                                        <li><a to="#">Conseil Juridique</a>
                                            <ul>
                                                <li><Link to="/membre/?expert=true">Nos experts</Link></li>
                                                <li><Link to="/expertises">Nos domaines</Link></li>
                                            </ul>
                                        </li>
                                        <li><a href="#">Base de données</a>
                                            <ul>
                                                {Array.isArray(law_categories_data) && law_categories_data.map((law_category) => (
                                                    <li><Link to={{pathname: '/base-de-donnees/', search: '?category='+law_category._id}} state={{ law_category: law_category._id }}>{law_category.category}</Link></li>
                                                ))}
                                            </ul>
                                        </li>
                                        <li><Link to="/activite" state={{ type: "formation" }}>Formations</Link></li>
                                        <li><a href="#">Projets</a>
                                            <ul>
                                                <li><Link to="/plaidoyer">Plaidoyers</Link></li>
                                                <li><Link to="/activite" state={{ training_type: "sensibilisation" }}>Sensibilisation au droit</Link></li>
                                                <li><Link to="/kit-juridique">Kit Juridique</Link></li>
                                            </ul>
                                        </li>
                                        <li><Link to="/actualites">Actualités</Link></li>
                                    </ul>
                                </div>
                                {Array.isArray(company_details) && company_details.map((comp) => (
                                    <div className="de-flex-col">
                                        <div className="h-phone md-hide"><span>Besoin d'aide?</span><i className="fa fa-phone"></i> {comp.tel}</div>
                                        <span id="menu-btn" onClick={handleMenuBtnClick}></span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                <Link to="/chat-en-ligne" className="to-chat-button"><i className="fa fa-commenting-o" aria-hidden="true"></i></Link>
            </header>        
        </>

    )
}