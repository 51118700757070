import './legalkitdata.css';

import axios from 'axios';
import Swal from 'sweetalert2';
import { useEffect, useState } from 'react';
import parse from 'html-react-parser';
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { TailSpin, RotatingLines } from  'react-loader-spinner';

import LegalKitFiles from '../legalkitfiles/legalkitfiles';

export default function LegalKitData({ legal_kit_id }) {

	const [legal_kit, setLegalKitData] = useState([]);
    const [loadLegalKits, setLoadLegalKits] = useState("true");
    const [showNoData, setShowNoData] = useState("false");

	const loggedUser = useSelector(state=>state.user.user);

	useEffect(()=> {

		const getLegalKitData = async()=> {

			try{
				const legal_kit_data = await axios.get(process.env.REACT_APP_API_KEY+'/legal-kit/'+legal_kit_id);
				setLegalKitData(legal_kit_data.data);

				setLoadLegalKits("false");
				setShowNoData("true");
			} catch(err) {
				setShowNoData("true");
				toast.error(err.response.data, { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast1' });
			}
		}

		// functions call

		getLegalKitData();

	}, []);

	const removeLegalKit = (legalKit_id, legalKit_title)=> {

		Swal.fire({
		  title: 'Effacer le document '+legalKit_title,
		  text: "Vous ne pourrez pas revenir en arrière !",
		  icon: 'warning',
		  showCancelButton: true,
		  confirmButtonColor: '#3085d6',
		  cancelButtonColor: '#d33',
		  confirmButtonText: 'Oui, effacer !',
		  cancelButtonText: 'Non !'
		}).then((result) => {
		  if (result.isConfirmed) {

		    deleteLegalKit(legalKit_id);

		  }
		})

	}

	const deleteLegalKit = async(legalKit_id) => {

		toast.info("Opération en cours !", { position: toast.POSITION.TOP_RIGHT, autoClose: 5000, toastId: 'toast2' });

        try{
        	const legal_kit_delete_data = await axios.put(process.env.REACT_APP_API_KEY+"/legal-kit/delete/"+legalKit_id, {}, {
                headers: {
                    Authorization: loggedUser.token
                }
            });

            setShowNoData("true");

            toast.success(legal_kit_delete_data.data.message, { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast1' });
        }
        catch(err) {
            toast.error("Une erreur est survenue : "+err.response.data.message, { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast1' });
        }

    }

	return (

		<section aria-label="section">
            <div className="container">

            	{showNoData === 'true' ? <div className="no-data">Données supprimées</div> : 

		            <div className="row">

		                <div className="col-md-4">
		                	<LegalKitFiles legal_kit_id={ legal_kit_id } />
		                </div>

		               	<div className="col-md-8">

			                {loggedUser && loggedUser.is_admin === true ? 

			            		<>

			                        <section className="text-light search-section">
			                            <div className="container">
			                                <div className="row align-items-center">

			                                	<div className="col-md-2 text-lg-right text-sm-center">
				                                    <Link to={`/editer-kit-juridique/?param=${ legal_kit._id}`} className="clear-button">Editer </Link>
				                                </div>
				                                <div className="col-md-2 text-lg-right text-sm-center">
				                                    <button className="danger-button" onClick={ e=>removeLegalKit(legal_kit._id, legal_kit.title) }>Effacer </button>
				                                </div>

			                                </div>
			                            </div>
			                        </section>
		                        </>

		                    : "" }

		               		{loadLegalKits === 'true' ? 

					            <div className="data-loader">

					                <TailSpin 
					                    height="40"
					                    width="40"
					                    color="#000"
					                    ariaLabel="tail-spin-loading"
					                    radius="1"
					                    wrapperStyle={{}}
					                    wrapperClass=""
					                    visible={true}
				                    />

					            </div>

					            : "" 
					        }

		                	<h2 className="c_name"> { legal_kit.title }</h2>
		                	<br/><br/>
		                    <div className="comment">{typeof legal_kit.description === 'string' ? parse(legal_kit.description) : null}</div>
		                </div>

		            </div>

	        	}

            </div>
        </section>
        
	)

}
