import './userprofile.css';

import axios from 'axios';
import parse from 'html-react-parser';
import { Link, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { toast } from 'react-toastify';
import { useParams } from "react-router-dom";
import { Circles } from  'react-loader-spinner';
import { useDispatch, useSelector } from 'react-redux';
import { TailSpin, RotatingLines } from  'react-loader-spinner';

import SingleUser from '../../components/singleuser/singleuser';

export default function UserProfile() {

	const queryParams = new URLSearchParams(window.location.search);
	const param = queryParams.get('param');

    const loggedUser = useSelector(state=>state.user.user);
    
    const [load, setLoader] = useState('');
    const [expert, setExpertID] = useState("");
    const [userData, setUserData] = useState([]);
    const [expertise, setExpertise] = useState("");
    const [loadUserExpertises, setLoadUserExpertises] = useState("true");
    const [expert_expertises_data, setExpertExpertisesData] = useState([]);
    const [expertises_data, setExpertisesData] = useState([]);
    
    useEffect(()=>{

        setExpertID(param);

        const loadExpertises = async()=> {

            try{
                const expertisesRes = await axios.get(process.env.REACT_APP_API_KEY+"/expertise/");
                setExpertisesData(expertisesRes.data);
            }
            catch(err) {
                toast.error(err.response.data, { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast1' });
            }

        }

        const loadExpertExpertises = async()=> {

            try{
                const expertExpertisesRes = await axios.get(process.env.REACT_APP_API_KEY+"/experts/expert-expertises/"+param);
                setExpertExpertisesData(expertExpertisesRes.data);

                setLoadUserExpertises("false");
            }
            catch(err) {
                toast.error(err.response.data, { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast1' });
            }
        }

        const getUserData = async()=> {
            try {
                const user_data = await axios.get(process.env.REACT_APP_API_KEY+'/users/'+param);
                setUserData(user_data.data);
            }
            catch(err) {
                toast.error(err.response.data, { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast1' });
            }
        }

        // functions call

        getUserData();
        loadExpertises();
        loadExpertExpertises();

    }, []);

    const addExpert = async()=> {

        if((expertise == "")) {
            toast.warning("Sélectionnez une expertise pour poursuivre !", { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast1' });
        }
        else
        {
            setLoader(true);

            try{
                const expertAddRes = await axios.post(process.env.REACT_APP_API_KEY+"/experts/", { expert, expertise }, {
                    headers: {
                        Authorization: loggedUser.token
                    }
                });

                setExpertExpertisesData(expertAddRes.data);

                setLoader(false);

                toast.success("Expertise ajoutée avec succès !", { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast1' });
            }
            catch(err) {
                setLoader(false);
                toast.error(err.response.data, { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast1' });
            }
        }

    }

    const removeExpertExpertise = async(expert_expertises_id) => {

        toast.info("Opération en cours !", { position: toast.POSITION.TOP_RIGHT, autoClose: 5000, toastId: 'toast2' });

        try{
            const expertDeleteRes = await axios.put(process.env.REACT_APP_API_KEY+"/experts/delete/"+expert_expertises_id, null, {
              headers: {
                Authorization: loggedUser.token
              }
            });

            setExpertExpertisesData(expertDeleteRes.data);
            toast.success("Expertise effacée avec succès !", { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast1' });
        }
        catch(err) {
            toast.error(err.response.data.message, { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast1' });
        }

    }

    return(
        
        <>
            <div className="top-section">.</div>
        	<section aria-label="section">
                <div className="container">
                    <div className="row">

                        {loggedUser ?

                            <section className="text-light search-section">
                                <div className="container">
                                    <div className="row align-items-center">

                                        {loggedUser.slug === param || loggedUser.is_admin === true ? 

                                            <div className="col-md-2 text-lg-right text-sm-center">
                                                <Link to={`/editer-utilisateur/?param=${ userData._id}`} className="clear-button">Editer </Link>
                                            </div>

                                        : '' }

                                        {loggedUser.slug === param ? 

                                            <div className="col-md-6 text-lg-right text-sm-center">
                                                <Link to="/changer-mot-de-passe" className="full-button">Changer de mot de passe</Link>
                                            </div>

                                        : '' }

                                    </div>
                                </div>
                            </section>

                        : '' }

                        {/*<div className="col-md-12 text-center">
                                                    <h2>Base de donnees</h2>
                                                    <div className="small-border"></div>
                                                </div> */}

                        <SingleUser userid ={ param } />

                        <section aria-label="section">
                            <div className="container">
                                <div className="row">

                                    {loggedUser && loggedUser.is_admin === true ? 

                                        <div className="col-md-12">
                                            <select className="form-control" onChange={ e=>setExpertise(e.target.value) } >
                                                <option value=""> -- Selectionner une expertise -- </option>
                                                {expertises_data?.map((expertise)=> (
                                                    <option value={expertise._id}> {expertise.name} </option>
                                                ))}
                                            </select>

                                            {load === true ?
                                                <a className="posting-load">
                                                    <Circles 
                                                        height="50"
                                                        width="50"
                                                        color="#000"
                                                        ariaLabel="circles-loading"
                                                        wrapperStyle={{}}
                                                        wrapperClass=""
                                                        visible={true}
                                                    />
                                                </a> : <button className="full-button" onClick={ addExpert }>Ajouter expertise </button> 
                                                
                                            }

                                        </div>

                                    : '' }

                                    <div className="col-md-12">
                                        <div className="comment-info">
                                            <br/>

                                            <h3>Expertises </h3>

                                            {loadUserExpertises === 'true' ? 

                                                <div className="data-loader">

                                                    <TailSpin 
                                                        height="40"
                                                        width="40"
                                                        color="#000"
                                                        ariaLabel="tail-spin-loading"
                                                        radius="1"
                                                        wrapperStyle={{}}
                                                        wrapperClass=""
                                                        visible={true}
                                                    />

                                                </div>

                                                : "" 
                                            }

                                            <div className="widget widget-post">
                                                <ul>
                                                    {expert_expertises_data?.map((expertise)=> (
                                                        <li>
                                                            <span className="expert-expertise-list">{ expertise.expertise.name }</span> 
                                                            {loggedUser && loggedUser.is_admin === true ? <span className="remove-expertise" onClick={ e=>removeExpertExpertise(expertise._id) }>X</span> : '' }
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </section>        

                    </div>
                </div>
            </section>   
        </>

    )
}