import './addemployee.css';

import axios from 'axios';
import parse from 'html-react-parser';
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { Circles } from  'react-loader-spinner';
import CKeditor from '../../components/CKeditor';

import { expertiseDataFetchSuccess } from '../../redux/expertisesSlice';

export default function AddEmployeeData() {

    const queryParams = new URLSearchParams(window.location.search);
    const company = queryParams.get('param');

    const [ code, setCode ] = useState("");
    const [ employee, setEmployee ] = useState("");
    const [ position, setPosition ] = useState("");
    const [ users_data, setUsersData ] = useState([]);
    const [load, setLoader] = useState('');

    const [editorLoaded, setEditorLoaded] = useState(false);

    const loggedUser = useSelector(state=>state.user.user);

    useEffect(()=>{

        const getUsersList = async()=> {

            try{
                const usersListRes = await axios.get(process.env.REACT_APP_API_KEY+"/users/");
                setUsersData(usersListRes.data);
            }
            catch(err) {
                toast.error("Erreur de serveur : les catégories n'ont pas été récupérées !", { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast1' });
            }
        }

        getUsersList();

    }, []);

    const postData = async(e) => {
        e.preventDefault();

        if((code == "")||(employee == "")||(position == "")) {
            toast.warning("Veuillez remplir tout le formulaire avant de poursuivre !", { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast1' });
        }
        else
        {
            setLoader(true);

            try{

                const data_post = await axios.post(process.env.REACT_APP_API_KEY+"/employee", {code, employee, company, position}, {
                        headers: {
                            Authorization: loggedUser.token
                        }
                    });

                setCode("");
                setPosition("");

                setLoader(false);

                toast.success(data_post.data.message, { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast1' });
            }
            catch(err) {
                setLoader(false);
                toast.error("Une erreur est survenue !", { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast1' });
            }

        }

    }

    return(
        
        <>
            <div className="top-section">.</div>

            {loggedUser && loggedUser.is_admin === true ? 

            	<section aria-label="section">
                    <div className="container">
                        <div className="row">

                            <div id="comment-form-wrapper">

                                <h4 className="form-title">Ajouter un employé</h4>
                                <br/>

                                <div className="form-default">
                                    <label>Employé <span className="req">*</span></label>
                                    <select className="input" onChange={ e=>setEmployee(e.target.value) } >
                                        <option value=""> -- Veuillez choisir l'employé --</option>
                                        {users_data?.map((user)=> (
                                            <option value={ user._id }> {user.first_name} {user.last_name} </option>
                                        ))}
                                    </select>
                                    <label>Code employé <span className="req">*</span></label>
                                    <input type="text" className="input" value={ code } onChange={ e=>setCode(e.target.value) } />
                                    <label>Titre <span className="req">*</span></label>
                                    <input type="text" className="input" value={ position } onChange={ e=>setPosition(e.target.value) } />

                                    {load === true ?
                                        <a className="posting-load">
                                            <Circles 
                                                height="40"
                                                width="40"
                                                color="#000"
                                                ariaLabel="circles-loading"
                                                wrapperStyle={{}}
                                                wrapperclass=""
                                                visible={true}
                                            />
                                        </a> : <button className="btn btn-custom" onClick={ postData }>Ajouter</button>   
                                        
                                    }

                                </div>

                            </div>
                            
                        </div>
                    </div>
                </section>   

            :

                <div className="kode-content">
                    <div className="kode-about-us-section">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    Vous n'êtes pas autorisé(e) à accéder à cette section !
                                </div> 
                            </div>
                        </div> 
                    </div>
                </div> 
                        
            }
        </>

    )
}