import './event.css';

import axios from 'axios';
import { useEffect, useState } from 'react';
import parse from 'html-react-parser';
import moment from "moment";
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
import { TailSpin, RotatingLines } from  'react-loader-spinner';

export default function Events({ search_term, limit }) {

    const [events_list, setEventsData] = useState([]);
    const [loadEvents, setLoadEvents] = useState("true");

    useEffect(()=> {

        const getEventsData = async()=> {

            try {

                const events = await axios.get(process.env.REACT_APP_API_KEY+'/events/?search_term='+search_term+'&limit='+limit);
                setEventsData(events.data);

                setLoadEvents("false");

                let isEmpty = Object.keys(events.data).length === 0;

            }
            catch(err) {
                toast.error(err.response.data, { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast1' });
            }
        }

        // functions call

        getEventsData();

    });

	return (

            <section aria-label="section">

                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="text-center">
                                <h2 className="title-padding">Événements</h2>
                            </div>

                            {loadEvents === 'true' ? 

                                <div className="data-loader">

                                    <TailSpin
                                        height="40"
                                        width="40"
                                        color="#000"
                                        ariaLabel="tail-spin-loading"
                                        radius="1"
                                        wrapperStyle={{}}
                                        wrapperClass=""
                                        visible={true}
                                    />

                                </div>

                                : "" 
                            }  

                            {events_list.length === 0 ? <p>Pas d'événements pour le moment !</p> :

                                <div className="row">
                                
                                    {Array.isArray(events_list) && events_list.map((event) => (

                                        <div className="col-lg-6 col-md-6 mb30">
                                            <div className="event-card">
                                                <img className="event-image" alt="" src={event.image}/>
                                                <div className="event-data">
                                                    <h2>{event.title}</h2>
                                                    <div className="event-date">
                                                        {event.date} | {event.venue}
                                                    </div>
                                                    <div className="event-details">{parse(event.description.substring(0, 130))}</div>
                                                    <Link to={{pathname: '/evenement-details/', search: '?param='+event._id}} className="read-more">Lire Plus</Link>
                                                </div>
                                            </div>
                                        </div>

                                    ))}

                                </div>

                            }

                        </div>
                    </div>
                </div>
            
            </section>
        
	    )

}