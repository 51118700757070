import './createaccount.css';

import axios from 'axios';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { Circles } from  'react-loader-spinner';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { loginFailure, loginStart, loginSuccess } from '../../redux/userSlice';

export default function CreateAccount() {

    const navigate = useNavigate();

    const loggedUser = useSelector(state=>state.user.user);

    /*if(loggedUser) {
        window.location.href = "/";
        toast.info("Vous êtes déjà enregistré(e) !", { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast1' });
    }*/
    
    const [load, setLoader] = useState('');
    const [first_name, setFName] = useState("");
    const [last_name, setLName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [passwordConfirmation, setPasswordConfirmation] = useState("");

    const dispatch = useDispatch();

    const handleAccountCreation = async(e) => {
        e.preventDefault();

        if((first_name == "")||(last_name == "")||(email == "")||(password == "")||(passwordConfirmation == "")) {
            toast.warning("Veuillez remplir tout le formulaire avant de poursuivre !", { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast1' });
        }
        else
        {
            if(passwordConfirmation != password)
            {
                toast.error("Les deux mots de passe ne correspondent pas. Veuillez vérifier votre mot de passe et réessayer !", { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast1' });
            }
            else
            {
                setLoader(true);

                try{

                    const signUpRes = await axios.post(process.env.REACT_APP_API_KEY+"/auth/sign-up", {first_name, last_name, email, password, passwordConfirmation});

                    setFName("");
                    setLName("");
                    setEmail("");
                    setPassword("");
                    setPasswordConfirmation("");

                    setLoader(false);

                    navigate('/connection');

                    toast.success(signUpRes.data.message, { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast1' });
                }
                catch(err) {
                    setLoader(false);
                    toast.error("Erreur de création de compte : "+err.response.data.message, { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast1' });
                }
            }
        }

    }

    return(

        <>
            <div className="top-section">.</div>

            {loggedUser ? 

                <div className="kode-content">
                    <section className="kode-about-us-section">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-6 success-message">
                                    Vous êtes déjà connecté(e)
                                </div> 
                            </div>
                        </div> 
                    </section>
                </div> 
                :
            
                <section aria-label="section">
                    <div className="container">
                        <div className="row">

                            <div className="col-md-8">
                                <h2 className="account-creation-title">Créer un compte</h2>
                                <div className="account-creation-form">
                                    <input className='input' type="text" placeholder='Nom *' value={ first_name } onChange={e=>setFName(e.target.value)}/>
                                    <input className='input' type="text" placeholder='Prénom *' value={ last_name } onChange={e=>setLName(e.target.value)}/>
                                    <input className='input' type="text" placeholder='E-mail *' value={ email } onChange={e=>setEmail(e.target.value)}/>
                                    <input className='input' type="password" placeholder='Mot De Passe' value={ password } onChange={e=>setPassword(e.target.value)}/>
                                    <input className='input' type="password" placeholder='Confirmer mot De Passe' value={ passwordConfirmation } onChange={e=>setPasswordConfirmation(e.target.value)}/>
                                    
                                    {load === true ?
                                        <a className="posting-load">
                                            <Circles 
                                                height="40"
                                                width="40"
                                                color="#000"
                                                ariaLabel="circles-loading"
                                                wrapperStyle={{}}
                                                wrapperClass=""
                                                visible={true}
                                            />
                                        </a> : <button className="submit-button" onClick={ handleAccountCreation }>Ajouter</button>   
                                        
                                    }

                                    <br/><br/>
                                    <Link className="login-link" to="/connection">J'ai déjà un compte. Je me Connecte !</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            }
        </>
        
    )
}