import './event.css';

import axios from 'axios';
import { Link, useLocation } from "react-router-dom";
import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';
import { LineWave } from  'react-loader-spinner';
import { useSelector } from 'react-redux';

import Events from '../../components/event/event';

export default function EventsPage() {

    const [search_term, setSearchTerm] = useState("");
    const [load, setLoader] = useState('');

    const loggedUser = useSelector(state=>state.user.user);

    useEffect(()=> {

        window.scrollTo(0, 0);

    }, []);

	return(
		<>
            <div className="top-section">.</div>
            <section aria-label="section" className="background">
                <div className="container">
                        
                        <section className="search-section text-light">
                            <div className="container">
                                <h4 className="filter-title">Filtrer</h4>
                                <div className="small-border"></div>
                                <br/>
                                <div className="row news-search-card align-items-center">
                                    <div className="col-md-4 mb-sm-30 padding-left-0 text-sm-center">
                                        <input type="text" className="search-field" value={search_term} onChange={e=>setSearchTerm(e.target.value)} placeholder="Rechercher ..." />
                                    </div>

                                    {loggedUser && loggedUser.is_admin === true ? 

                                        <div className="col-md-4 text-lg-right text-sm-center">
                                            <Link to="/ajouter-un-evenement" className="add-database">Ajouter un Evénements</Link>
                                        </div>

                                    : "" }
                                </div>
                            </div>
                        </section>

                    <div className="col-md-12">
                        <Events search_term={search_term} />
                    </div>
                </div>
            </section>

		</>
	)

}