import './pleadingdata.css';

import axios from 'axios';
import Swal from 'sweetalert2';
import { useEffect, useState } from 'react';
import parse from 'html-react-parser';
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { TailSpin, RotatingLines } from  'react-loader-spinner';

export default function PleadingData({ pleading_id }) {

	const [plead, setPleadingData] = useState([]);
    const [loadPleading, setLoadPleading] = useState("true");
    const [showNoData, setShowNoData] = useState("false");

	const loggedUser = useSelector(state=>state.user.user);

	useEffect(()=> {

		const getPleadingData = async()=> {

			try{
				const pleading_data = await axios.get(process.env.REACT_APP_API_KEY+'/pleading/'+pleading_id);
				setPleadingData(pleading_data.data);

				setLoadPleading("false");
			} catch(err) {
				setShowNoData("true");
				toast.error(err.response.data, { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast1' });
			}
		}

		// functions call

		getPleadingData();

	}, []);

	const agree = async()=> {

		const number_of_supports = plead.number_of_supports+1;

		try{
			const pleading_agree_data = await axios.post(process.env.REACT_APP_API_KEY+'/pleading/'+pleading_id, { number_of_supports }, {
                headers: {
                    Authorization: loggedUser.token
                }
            });
			setPleadingData(pleading_agree_data.data);
		} catch(err) {
			toast.error(err.response.data, { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast1' });
		}
	}

	const disagree = async()=> {

		const number_of_no_supports = plead.number_of_no_supports+1;

		try{
			const pleading_disagree_data = await axios.post(process.env.REACT_APP_API_KEY+'/pleading/'+pleading_id, { number_of_no_supports }, {
                headers: {
                    Authorization: loggedUser.token
                }
            });
			setPleadingData(pleading_disagree_data.data);
		} catch(err) {
			toast.error(err.response.data, { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast1' });
		}
	}

	const removePleading = (pleading_id, pleading_name)=> {

		Swal.fire({
		  title: 'Effacer '+pleading_name,
		  text: "Vous ne pourrez pas revenir en arrière !",
		  icon: 'warning',
		  showCancelButton: true,
		  confirmButtonColor: '#3085d6',
		  cancelButtonColor: '#d33',
		  confirmButtonText: 'Oui, effacer !',
		  cancelButtonText: 'Non !'
		}).then((result) => {
		  if (result.isConfirmed) {

		    deletePleading(pleading_id);

		  }
		})

	}

	const deletePleading = async(pleading_id) => {

		toast.info("Opération en cours !", { position: toast.POSITION.TOP_RIGHT, autoClose: 5000, toastId: 'toast2' });

        try{
        	const training_delete_data = await axios.put(process.env.REACT_APP_API_KEY+"/pleading/delete/"+pleading_id, {}, {
                headers: {
                    Authorization: loggedUser.token
                }
            });

            setShowNoData("true");

            toast.success(training_delete_data.data.message, { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast1' });
        }
        catch(err) {
            toast.error("Une erreur est survenue : "+err.response.data.message, { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast1' });
        }

    }

	return (

		<section aria-label="section">
            <div className="container">

	            <div className="row">

	            	{loggedUser ?

                        <section className="text-light search-section">
                            <div className="container">
                                <div className="row align-items-center">

                                    { loggedUser.is_admin === true ? 

                                    	<>
	                                        <div className="col-md-2 text-lg-right text-sm-center">
	                                            <Link to={`/editer-expertise/?param=${ plead._id}`} className="clear-button">Editer </Link>
	                                        </div>
	                                        <div className="col-md-2 text-lg-right text-sm-center">
	                                            <button className="danger-button" onClick={ e=>removePleading(plead._id, plead.title) }>Effacer </button>
	                                        </div>
                                        </>

                                    : '' }

                                </div>
                            </div>
                        </section>

                    : '' }

                    {loadPleading === 'true' ? 

		                <div className="data-loader">

		                    <TailSpin 
		                        height="40"
		                        width="40"
		                        color="#000"
		                        ariaLabel="tail-spin-loading"
		                        radius="1"
		                        wrapperStyle={{}}
		                        wrapperClass=""
		                        visible={true}
		                    />

		                </div>

		                : "" 
		            }

		            {showNoData === 'true' ? <div className="no-data">Données supprimées</div> : 

		            	<>

			               	<div className="col-md-12">
			                	<h2 className="c_name"> { plead.title }</h2>
			                	<br/><br/>
			                    <div className="comment">{typeof plead.content === 'string' ? parse(plead.content) : null}</div>
			                </div>

			                <div className="col-md-12">
			                    <span className="post-date"> { plead.number_of_supports } accords </span>
			                    <span className="post-date"> { plead.number_of_no_supports } désaccords </span>
			                </div>

			                <div className="col-md-12">
			                    <button className="post-date agreement-button" onClick={ agree }><i className="fa fa-thumbs-o-up" aria-hidden="true"></i> &nbsp; Je suis d'accord </button>
			                    <button className="post-date agreement-button" onClick={ disagree }><i className="fa fa-thumbs-o-down" aria-hidden="true"></i> &nbsp; Je ne suis pas d'accord </button>
			                </div>

			            </>

		            }

	            </div>

            </div>
        </section>
        
	)

}
