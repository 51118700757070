import './chatgptcpnt.css';

import axios from 'axios';
import { useSelector } from 'react-redux';
import { Configuration, OpenAIApi } from 'openai';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Circles, TailSpin, RotatingLines } from  'react-loader-spinner';

export default function ChatGptCpnt() {

    const loggedUser = useSelector(state=>state.user.user);
    
    const [load, setLoader] = useState('');
    const [question, setQuestion] = useState('');
    const [loadConversations, setLoadConversations] = useState("true");
    const [conversations_list, setConversations] = useState([]);

    useEffect(()=>{

        const getUserConversations = async()=>{

            try{

                const conversations_data = await axios.put(process.env.REACT_APP_API_KEY+"/chatgpt-conversations", {}, {
                    headers: {
                        Authorization: loggedUser.token
                    }
                });

                setConversations(conversations_data.data);

                setLoadConversations("false");
            }
            catch(err) {
                setLoadConversations("false");
                toast.error("Une erreur est survenue !", { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast1' });
            }

        }

        getUserConversations();
        
    }, []);


    const configuration = new Configuration({
        apiKey: "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",
    });

    const openai = new OpenAIApi(configuration);

    const generateResponse = async () => {

        if((question == "")) {
            toast.warning("Veuillez saisir la question !", { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast1' });
        }
        else
        {
            setLoader(true);

            toast.info("Opération en cours !", { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast2' });

            let options = {
                model: 'text-davinci-003',
                temperature: 0,
                max_tokens: 2500,
                top_p: 1,
                frequency_penalty: 0.0,
                presence_penalty: 0.0,
                stop: ['/'],
            };

            let completeOptions = {
                ...options,
                prompt: question,
            };

            try
            {
                const response = await openai.createCompletion(completeOptions);

                if (response.data.choices) {

                    /*new_nbr_prompts = nbr_prompts+1;
                    setNbrPrompts(new_nbr_prompts);


                    setQuestionAnswers([
                        {
                            nbr_of_message: nbr_prompts,
                            question: question,
                            answer: response.data.choices[0].text,
                        },
                        ...question_answers,
                    ]);*/

                    const answer = response.data.choices[0].text;
                    
                    try{

                        const send_chat_message = await axios.post(process.env.REACT_APP_API_KEY+"/chatgpt-conversations", {question, answer}, {
                                headers: {
                                    Authorization: loggedUser.token
                                }
                            });

                        setQuestion('');
                        setConversations(send_chat_message.data);

                        setLoader(false);
                    }
                    catch(err) {
                        setLoader(false);
                        toast.error("Une erreur est survenue !", { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast1' });
                    }

                }

            }
            catch(err)
            {
                toast.error("Erreur: "+err, { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast1' });
            }

        }

    };

    
    return(

        <>
            {loggedUser ? 

                <section aria-label="section">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-10">

                                {loadConversations === 'true' ? 

                                    <div className="data-loader">

                                        <TailSpin 
                                            height="40"
                                            width="40"
                                            color="#000"
                                            ariaLabel="tail-spin-loading"
                                            radius="1"
                                            wrapperStyle={{}}
                                            wrapperclassName=""
                                            visible={true}
                                        />

                                    </div>

                                    : "" 
                                }

                                <div className="spacer-single"></div>
                                <div id="blog-comment">
                                    <h4 className="form-title">Discussion en ligne</h4>
                                    <div className="small-border"></div>
                                    <br/><br/><br/>


                                    {conversations_list?.map((qr)=>(

                                        <>
                                            <div className="sender-card">

                                                <div className="sender-side">
                                                    {/*<h4 className="messager-name">Moi</h4>*/}
                                                    <img src="images/misc/avatar.png" alt="" />
                                                </div>
                                                <div className="message-side">
                                                    { qr.question }
                                                </div>

                                            </div>

                                            <div className="replier-card">

                                                <div className="replier-sender-side">
                                                    {/*<h4 className="messager-name">Moi</h4>*/}
                                                    <img src="images/misc/ld-profile.png" alt="" />
                                                </div>
                                                <div className="replier-message-side">
                                                    { qr.answer }
                                                </div>

                                            </div>

                                        </>

                                    ))}

                                    <div className="spacer-single"></div>
                                    <div className="chat-field-group">
                                        <input placeholder="Message ..." className="chat-field" value={ question }  onChange={ e=>setQuestion(e.target.value) } />


                                        {load === true ?
                                        <a className="sending-message-load">
                                            <Circles 
                                                height="40"
                                                width="40"
                                                color="#000"
                                                ariaLabel="circles-loading"
                                                wrapperStyle={{}}
                                                wrapperclass=""
                                                visible={true}
                                            />
                                        </a> : <button className="send-chat-message-button" onClick={ generateResponse }><i className="fa fa-paper-plane-o" aria-hidden="true"></i></button>
                                        
                                    }

                                    </div> 

                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            :

                <div className="kode-content">
                    <div className="kode-about-us-section">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    Vous n'êtes pas autorisé(e) à accéder à cette section !
                                </div> 
                            </div>
                        </div> 
                    </div>
                </div> 
                        
            }
        </>

    )
}