import './home.css';

import axios from 'axios';
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import parse from 'html-react-parser';
import { useDispatch, useSelector } from 'react-redux';
import { TailSpin, RotatingLines } from  'react-loader-spinner';

import News from '../../components/news/news';
import Events from '../../components/event/event';
import Partners from '../../components/partners/partners';

import { companyDataFetchStart, companyDataFetchSuccess, companyDataFetchFailure } from '../../redux/companySlice';

export default function Home() {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [sliders_list, setSliders] = useState([]);
    const [loadSliders, setLoadSliders] = useState("true");
    
    const [company_sent_data, setCompanyData] = useState([]);
    const [loadCompanyData, setLoadCompanyData] = useState("true");

    const [experts_search_term, setExpertsSearchTerm] = useState([]);
    const [database_search_term, setDatabaseSearchTerm] = useState([]);

    const host_company_data = useSelector(companyDataFetchSuccess);

    useEffect(()=>{

        const getSliders = async()=>{

            try{

                const slidersRes = await axios.get(process.env.REACT_APP_API_KEY+'/slider', { mode: 'cors' });
                setSliders(slidersRes.data);

                setLoadSliders("false");

            }
            catch(err) {
                toast.error(err.response.data, { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast1' });
            }

        }    

        const getCompanyData = async()=>{

            try{

                const companyRes = await axios.get(process.env.REACT_APP_API_KEY+"/company/?host_organization=true");
                setCompanyData(companyRes.data);

                setLoadCompanyData("false");
            }
            catch(err) {
                setLoadCompanyData("false");
                toast.error(err.response.data, { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast1' });
            }

            
        }

        // functions call 

        getSliders();

        if (Object.keys(host_company_data.payload.companyData.companyData).length === 0) {
            getCompanyData();          
        }
        else
        {
            setCompanyData(host_company_data.payload.companyData.companyData);

            setLoadCompanyData("false");
        }

    }, []);

    const search = () => {

        if((experts_search_term == '')&&(database_search_term == ''))
        {
            toast.warning('Aucun élément à rechercher. Veuillez saisir au moins un terme de recherche pour continuer !', { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast1' });
        }
        else
        {
            navigate('/recherche/?expert='+experts_search_term+'&base_de_donnee='+database_search_term);
        }

    }
    
    
    return(

        <>
            <div className="no-bottom no-top" id="content">
                <div id="top"></div>

                {loadSliders === 'true' ? 

                    <div className="data-loader">

                        <TailSpin
                            height="40"
                            width="40"
                            color="#000"
                            ariaLabel="tail-spin-loading"
                            radius="1"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                        />

                    </div>

                    : "" 
                }

                {Array.isArray(sliders_list) && sliders_list.map((slider) => (

                    <section aria-label="section" className="jarallax home-slider">

                        <img alt="" src={slider.image}/>
        
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-lg-5 offset-md-7 wow fadeInRight" data-wow-delay=".5s">
                                    <div className="spacer-double"></div>
                                    <div className="spacer-double"></div>
                                    <h3 className="id-color wow fadeInUp" data-wow-delay=".4s">{slider.sub_title}</h3>
                                    <h1 className="wow fadeInUp" data-wow-delay=".6s">{slider.title}</h1>
                                    <p className="lead wow fadeInUp" data-wow-delay=".8s">{slider.short_description}</p>
                                    <div className="spacer-20"></div>
                                    <Link className="btn-custom wow fadeInUp" data-wow-delay="1s" to="/contact">Nous Contacter</Link>
                                    <div className="spacer-double"></div>
                                </div>
                            </div>
                        </div>

                    </section>

                ))}         

                <section className="pt40 pb40 bg-color text-light">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-2 mb-sm-30 text-lg-left text-sm-center">
                                <h3 className="no-bottom">RECHERCHE</h3>
                            </div>
                            <div className="col-md-4 mb-sm-30 text-lg-left text-sm-center">
                                <input className="search-field" value={experts_search_term} onChange={e=>setExpertsSearchTerm(e.target.value)} placeholder="Dans les experts" type="text" />
                            </div>
                            <div className="col-md-4 mb-sm-30 text-lg-left text-sm-center">
                                <input className="search-field" value={database_search_term} onChange={e=>setDatabaseSearchTerm(e.target.value)} placeholder="Dans la base de données" type="text" />
                            </div>
                            <div className="col-md-2 text-lg-right rtl-lg-left text-sm-center">
                                <button className="btn-custom btn-black light" onClick={search}>Chercher</button>
                            </div>
                        </div>
                    </div>
                </section>  

                {loadCompanyData === 'true' ? 

                    <div className="data-loader">

                        <TailSpin
                            height="40"
                            width="40"
                            color="#000"
                            ariaLabel="tail-spin-loading"
                            radius="1"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                        />

                    </div>

                    : "" 
                }              

                {Array.isArray(company_sent_data) && company_sent_data.map((company) => (

                    <section aria-label="section" data-bgcolor="#ffffff">
                        <div className="container">
                            <div className="row align-items-center">

                                <div className="col-md-7 text-justify">
                                    {/*<span className="p-title">Mot du directeur</span><br/>*/}
                                    <h2>Bienvenue !</h2>
                                    {parse(company.director_words)}
                                </div>
                                <div className="col-md-4 offset-md-1">
                                    <div className="de-images">
                                        <div className="di-text text-white bg-color">
                                            <span>{company.director.title} {company.director.last_name} {company.director.first_name}</span>
                                        </div>
                                        <div className="image-bar">......</div>
                                        <img className="di-big img-fluid" src={company.welcome_image} alt="" />
                                    </div>
                                </div>

                            </div>
                        </div>
                    </section>

                ))}

                <section id="section-highlight" className="relative background" data-bgcolor="#f2f2f2">
                    <div className="container">
                        <div className="row">
                            
                            <div className="col-lg-4">
                                <Link to="/membre/?expert=true">
                                    <div className="card-nav centered">
                                        <i className="fa fa-users"></i><br/>
                                        <h2>Nos experts</h2>
                                        <p>
                                            une équipe de professionnels du Droit composée d'avocats, d'enseignants, de juristes,etc. pour vous accompagner.
                                        </p>
                                    </div>
                                </Link>
                            </div>
                            
                            <div className="col-lg-4 col-md-6 mb30 domain-card-padding">
                                <Link to="/expertises">
                                    <div className="card-nav">
                                        <i className="fa fa-cubes"></i><br/>
                                        <h2>Nos domaines</h2>
                                        <p>
                                            Le droit des affaires, le Droits de le Droit de l'Immobilier, le Droit de la Famille, le Droit du Travail, le Droit des obligations, etc.
                                        </p>
                                    </div>
                                </Link>
                            </div>
                                                        
                            <div className="col-lg-4 col-md-6 mb30">
                                <Link to="/recherche">
                                    <div className="card-nav">
                                        <i className="fa fa-database"></i><br/>
                                        <h2>Notre Base De Données</h2>
                                        <p>
                                            Des documents juridiques, lois, jurisprudence, commentaires et analyses à votre disposition
                                        </p>
                                    </div>
                                </Link>
                            </div>

                            <div className="col-lg-4 col-md-6 mb30">
                                <Link to="/activite" state={{ training_type: "sensibilisation" }}>
                                    <div className="card-nav">
                                        <i className="fa fa-mortar-board"></i><br/>
                                        <h2>Nos Formations</h2>
                                        <p>
                                            De nombreuses formations professionnelles certifiantes en Droit, en Business, en Développement personnel, avec des modules interactifs pour les entreprises et les particuliers.
                                        </p>
                                    </div>
                                </Link>
                            </div>
                            
                            <div className="col-lg-4 col-md-6 mb30">
                                <Link to="/actualites">
                                    <div className="card-nav">
                                        <i className="fa fa-book"></i><br/>
                                        <h2>Actualités</h2>
                                        <p>
                                            Découvrez nos articles, analyses et podcasts sur des sujets juridiques pertinents, rédigés par des experts dans divers domaines. Tenez-vous à jour sur l'évolution du droit pour vous proteger.
                                        </p>
                                    </div>
                                </Link>
                            </div>

                            <div className="col-lg-4 col-md-6 mb30">
                                <Link to="/plaidoyer">
                                    <div className="card-nav">
                                        <i className="fa fa-connectdevelop"></i><br/>
                                        <h2>Nos Projets</h2>
                                        <p>
                                            Notre programme pro bono initie de nombreux projets pour rendre le Droit comprehensible et accessible et utile à tous et à tout moment afin de faire évoluer le Droit.
                                        </p>
                                    </div>
                                </Link>
                            </div>

                        </div>
                    </div>
                </section>

                <Events limit={'4'}/>

                {/*<Partners/>*/}
                <News limit={'3'}/>
                
            </div>
        </>

    )
}
