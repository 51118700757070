import './newsdata.css';

import axios from 'axios';
import Swal from 'sweetalert2';
import { useEffect, useState } from 'react';
import parse from 'html-react-parser';
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { TailSpin, RotatingLines } from  'react-loader-spinner';

export default function NewsData({ article_id }) {

	const [news, setNewsData] = useState([]);
    const [loadArticles, setLoadArticles] = useState("true");
    const [showNoData, setShowNoData] = useState("false");

	const loggedUser = useSelector(state=>state.user.user);

	useEffect(()=> {

		const getNewsData = async()=> {

			try{
				const news_data = await axios.get(process.env.REACT_APP_API_KEY+'/article/'+article_id);
				setNewsData(news_data.data);

				setLoadArticles("false");
			} catch(err) {
				setShowNoData("true");
				toast.error(err.response.data, { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast1' });
			}
		}

		// functions call

		getNewsData();

	}, []);

	const removeNews = (news_id, news_name)=> {

		Swal.fire({
		  title: 'Effacer '+news_name,
		  text: "Vous ne pourrez pas revenir en arrière !",
		  icon: 'warning',
		  showCancelButton: true,
		  confirmButtonColor: '#3085d6',
		  cancelButtonColor: '#d33',
		  confirmButtonText: 'Oui, effacer !',
		  cancelButtonText: 'Non !'
		}).then((result) => {
		  if (result.isConfirmed) {

		    deleteNews(news_id);

		  }
		})

	}

	const deleteNews = async(news_id) => {

		toast.info("Opération en cours !", { position: toast.POSITION.TOP_RIGHT, autoClose: 5000, toastId: 'toast2' });

        try{
        	const news_delete_data = await axios.put(process.env.REACT_APP_API_KEY+"/article/delete/"+news_id, {}, {
                headers: {
                    Authorization: loggedUser.token
                }
            });

            setShowNoData("true");

            toast.success(news_delete_data.data.message, { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast1' });
        }
        catch(err) {
            toast.error("Une erreur est survenue : "+err.response.data.message, { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast1' });
        }

    }

	return (

		<section aria-label="section">
            <div className="container">

	            <div className="row">

	            	{loadArticles === 'true' ? 

                        <div className="data-loader">

                            <TailSpin 
                                height="40"
                                width="40"
                                color="#000"
                                ariaLabel="tail-spin-loading"
                                radius="1"
                                wrapperStyle={{}}
                                wrapperClass=""
                                visible={true}
                            />

                        </div>

                        : "" 
                    }

	            	{loggedUser && loggedUser.is_admin === true ? 

	            		<section className="text-light search-section">
                            <div className="container">
                                <div className="row align-items-center">

                                    <div className="col-md-2 text-lg-right text-sm-center">
                                        <Link to={`/editer-article/?param=${ news._id}`} className="clear-button">Editer </Link>
                                    </div>
                                    <div className="col-md-2 text-lg-right text-sm-center">
                                    	<button className="danger-button" onClick={ e=>removeNews(news._id, news.title) }>Effacer </button>
                                    </div>

                                </div>
                            </div>
                        </section>

                    : "" }

                    {showNoData === 'true' ? <div className="no-data">Données supprimées</div> : 

                    	<>

			                <div className="col-md-4">
			                	{ news.image ? <img className="profile-image" src={ news.image } alt="" /> : "" }
			                </div>

			               	<div className="col-md-12">
			                	<h2 className="c_name"> { news.title }</h2>

			                	{ news.category ? <span className="post-date"> { news.category.category } <br/><br/></span> : "" }
			                	<br/><br/>
			                    <div className="comment">{typeof news.article === 'string' ? parse(news.article) : null}</div>
			                </div>

			                {/*<div className="col-md-12">
			                    <span className="post-date"> { news.number_of_supports } accords </span>
			                    <span className="post-date"> { news.number_of_no_supports } désaccords </span>
			                </div>*/}

			            </>

			        }

	            </div>

            </div>
        </section>
        
	)

}
