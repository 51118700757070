import './legalkitdetails.css';

import axios from 'axios';
import Swal from 'sweetalert2';
import parse from 'html-react-parser';
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { toast } from 'react-toastify';
import { Circles } from  'react-loader-spinner';
import { useSelector } from 'react-redux';

import LegalKitData from '../../components/legalkitdata/legalkitdata';

export default function LegalKitDetails() {

    const queryParams = new URLSearchParams(window.location.search);
    const legal_kit_id = queryParams.get('param');

    const [load, setLoader] = useState('');
    const [ title, setTitle ] = useState("");
    const [ file, setUploadedFilePath ] = useState("");

    const loggedUser = useSelector(state=>state.user.user);

    const handleFileUpload = async(event) => {

        const current_file = event.target.files[0];
        const formData = new FormData();
        formData.append('file', current_file);

        try {
            const fileUploadData = await axios.post(process.env.REACT_APP_API_KEY+'/law-database/file-upload/', formData, {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            });
            setUploadedFilePath(fileUploadData.data.uploaded_file);
        } catch (error) {
            toast.error("Erreur lors du téléchargement du fichier : "+error, { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast1' });
        }

    };

    const postFile = async(e) => {
        e.preventDefault();

        if((title == "")||(file == "")) {
            toast.warning("Veuillez télécharger le fichier pour poursuivre !", { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast1' });
        }
        else
        {
            const legal_kit = legal_kit_id;
            setLoader(true);

            try{

                const legal_kit_document_post = await axios.post(process.env.REACT_APP_API_KEY+"/legal-kit-files", {title, file, legal_kit}, {
                    headers: {
                        Authorization: loggedUser.token
                    }
                });

                setTitle("");
                setUploadedFilePath("");

                setLoader(false);

                toast.success("Fichier ajouté avec succès !", { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast1' });
            }
            catch(err) {
                setLoader(false);
                toast.error("Une erreur est survenue : "+err.response.data.message, { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast1' });
            }

        }

    }

    return(
        
        <>
            <div className="top-section">.</div>


            	<section aria-label="section">
                    <div className="container">
                        <div className="row">

                            {loggedUser && loggedUser.is_admin === true ? 

                                    <div className="add-image-section">
                                        <div className="add-image-field">
                                            <h4>Ajouter Un Document : </h4>
                                        </div>
                                        <div className="document-title">
                                            <input type="text" value={ title } placeholder="Titre" onChange={ e=>setTitle(e.target.value) } />
                                        </div>
                                        <div className="add-image-file-field">
                                            <input type="file" onChange={ handleFileUpload } />
                                        </div>

                                        {load === true ?
                                            <a className="posting-load">
                                                <Circles 
                                                    height="40"
                                                    width="40"
                                                    color="#000"
                                                    ariaLabel="circles-loading"
                                                    wrapperStyle={{}}
                                                    wrapperclassName=""
                                                    visible={true}
                                                    />
                                            </a> : <button className="read-more black-button" onClick={ postFile }>Ajouter</button>   
                                                        
                                        }
                                    </div>

                            : "" }

                            <LegalKitData legal_kit_id={ legal_kit_id } />
                            
                        </div>
                    </div>
                </section>   

        </>

    )
}