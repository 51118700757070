import './footer.css';

import axios from 'axios';
import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { LineWave } from  'react-loader-spinner';

import { companyDataFetchStart, companyDataFetchSuccess, companyDataFetchFailure } from '../../redux/companySlice';

export default function Footer({company_data}) {

    const dispatch = useDispatch();
    
    const [our_company_data, setOurCompanyData] = useState([]);
    const [load_registration, setRegistrationLoader] = useState('');

    const stored_host_company_data = useSelector(companyDataFetchSuccess);

    useEffect(()=>{

        const getCompanyData = async()=>{
            const companyRes = await axios.get(process.env.REACT_APP_API_KEY+"/company/?host_organization=true");
            setOurCompanyData(companyRes.data);
        }

        if (Object.keys(stored_host_company_data.payload.companyData.companyData).length === 0) {
            getCompanyData();          
        }
        else
        {
            setOurCompanyData(stored_host_company_data.payload.companyData.companyData);
        }

    }, []);

    // add newsletter user

    const [newsletter_email, setNewsletterEmail] = useState("");

    const newsletterRegistration = async(e) => {
        e.preventDefault();

        setRegistrationLoader(true);

        try{
            const newsLetterRes = await axios.put(process.env.REACT_APP_API_KEY+"/users", {newsletter_email});
            setNewsletterEmail("");
            toast.success(newsLetterRes.data.message, { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast20' });
            setRegistrationLoader(false);
        }
        catch(err) {
            setRegistrationLoader(false);
            toast.error(err.response.data, { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast3' });
        }

    }

    return(

        <>
            {Array.isArray(our_company_data) && our_company_data.map((company) => (

                <>
                    <a href="#" id="back-to-top"></a>
                    <footer>
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-4">
                                    <div className="widget">
                                        <Link to="/"><img alt="" className="img-fluid mb20" src="images/logo-light.png"/></Link>
                                        <p>{company.short_description}</p>
                                        <address className="s1">
                                            <span><i className="fa fa-phone fa-lg"></i>{company.tel}</span>
                                            <span><i className="fa fa-envelope-o fa-lg"></i><a href="mailto:{company.email}">{company.email}</a></span>
                                            <span><i className="fa fa-map-marker fa-lg"></i>{company.address}</span>
                                        </address>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <h5 className="mb20">Liens Utiles</h5>
                                    <ul className="ul-style-2">
                                        <li><Link to="/a-propos/">A Propos</Link></li>
                                        <li><Link to="/recherche/">Base de données</Link></li>
                                        <li><Link to="/membre/?expert=true">Nos Experts</Link></li>
                                        <li><Link to="/activite" state={{ training_type: "sensibilisation" }}>Nos formations</Link></li>
                                        <li><Link to="/faq">FAQ</Link></li>
                                        <li><Link to="/evenements">Evénements</Link></li>
                                        <li><Link to="/politique-de-confidentialite">Politique de confidentialité</Link></li>
                                        <li><Link to="/contact">Nous contacter</Link></li>
                                    </ul>
                                </div>
                                <div className="col-lg-4">
                                    <div className="widget">
                                        <h5 className="">Newsletter</h5>
                                        <p>Inscrivez-vous à notre newsletter pour recevoir nos dernières nouvelles et mises à jour.</p>
                                        <form className="row" id="form_subscribe" method="post" name="form_subscribe">
                                            <div className="col text-center"> 
                                                <input className="form-control" value={newsletter_email} onChange={e=>setNewsletterEmail(e.target.value)} placeholder="Entrer votre adresse email" type="email" />
                                                {load_registration === true ?
                                                    <a className="newsletter-registration-load">
                                                        <LineWave
                                                          height="100"
                                                          width="100"
                                                          color="#4fa94d"
                                                          ariaLabel="line-wave"
                                                          wrapperStyle={{}}
                                                          wrapperClass=""
                                                          visible={true}
                                                          firstLineColor=""
                                                          middleLineColor=""
                                                          lastLineColor=""
                                                        />
                                                    </a> :                                         
                                                    <a id="btn-submit" className="anchor" onClick={newsletterRegistration}><i className="fa fa-long-arrow-right"></i></a>
                                                }
                                                <div className="clearfix"></div>
                                            </div>
                                        </form>
                                        {/*<div className="spacer-10"></div>
                                        <small>Your email is safe with us. We don't spam.</small>*/}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="subfooter">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="de-flex">
                                            <div className="de-flex-col">
                                                Développé par <a href="https://bakuri-technologies.com/" target="_blank"> Bakuri Technologies</a>
                                            </div>
                                            <div className="de-flex-col">
                                                <div className="social-icons">
                                                    <a href="{company.facebook_url}"><i className="fa fa-facebook fa-lg"></i></a>
                                                    <a href="{company.twitter_url}"><i className="fa fa-twitter fa-lg"></i></a>
                                                    {/*<a href="{company.linkedin_url}"><i className="fa fa-linkedin fa-lg"></i></a>*/}
                                                    <a href="{company.instagram_url}"><i className="fa fa-instagram fa-lg"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </footer>

                </>

            ))}

        </>

    )

}