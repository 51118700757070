import './addactivity.css';

import axios from 'axios';
import parse from 'html-react-parser';
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { Circles } from  'react-loader-spinner';
import CKeditor from '../../components/CKeditor';

import { expertiseDataFetchSuccess } from '../../redux/expertisesSlice';

export default function AddActivity() {

    const queryParams = new URLSearchParams(window.location.search);
    const activity_type = queryParams.get('activity_type');

    const [ is_training, setTraining ] = useState("");
    const [ is_sensibilization, setSensibilization ] = useState("");
    const [ is_presential, setPresential ] = useState("");
    const [ title, setTitle ] = useState("");
    const [ short_description, setShortDescription ] = useState([]);
    const [ content, setContent ] = useState([]);
    const [ file, setUploadedFilePath ] = useState("");
    const [ teacher, setTeacher ] = useState("");
    const [ teachers_list, setTeachersList ] = useState([]);
    const [ is_pdf, setIsPdf ] = useState("");
    const [ is_audio, setIsAudio ] = useState("");
    const [ is_video, setIsVideo ] = useState("");

    const [editorLoaded, setEditorLoaded] = useState(false);
    const [load, setLoader] = useState('');

    const loggedUser = useSelector(state=>state.user.user);

    const handleFileUpload = async(event) => {

        const current_file = event.target.files[0];
        const current_file_type = current_file.type;

        if(current_file_type === 'application/pdf') {
            setIsPdf(true);
            setIsAudio(false);
            setIsVideo(false);
        }
        else
        {
            const index = current_file_type.indexOf('/');
            const uploaded_file_type = current_file_type.substring(0, index);

            if(uploaded_file_type === 'audio') {
                setIsPdf(false);
                setIsAudio(true);
                setIsVideo(false);
            }
            else if(uploaded_file_type === 'video') {
                setIsPdf(false);
                setIsAudio(false);
                setIsVideo(true);
            }
            else
            {
                toast.error("Veuillez choisir un document pdf, une image ou une vidéo", { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast1' });
            }

        }

        const formData = new FormData();
        formData.append('file', current_file);

        try {
            const fileUploadData = await axios.post(process.env.REACT_APP_API_KEY+'/law-database/file-upload/', formData, {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            });
            setUploadedFilePath(fileUploadData.data.uploaded_file);
        } catch (error) {
            toast.error("Erreur lors du téléchargement du fichier : "+error, { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast1' });
        }

    };

    useEffect(()=>{

        setEditorLoaded(true);

        const setTrainingType = ()=> {

            setPresential(false);

            if(activity_type === "sensibilize") {
                setTraining(false);
                setSensibilization(true);
            }
            else
            {
                setTraining(true);
                setSensibilization(false);
            }

        }

        const getTeachersList = async()=> {

            try{
                const teachersListRes = await axios.get(process.env.REACT_APP_API_KEY+"/users/?is_trainer=true");
                setTeachersList(teachersListRes.data);
            }
            catch(err) {
                toast.error("Erreur de serveur : les formateurs n'ont pas été récupérés !", { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast1' });
            }

        }

        // functions

        setTrainingType();
        getTeachersList();


    }, []);

    const postData = async(e) => {
        e.preventDefault();

        if(is_presential == 'true') {
            setPresential(true);
        }
        else
        {
            setPresential(false);
        }

        if(file.split('.').pop() === "" ) {

        }

        if((title == "")||(short_description == "")||(content == "")||(file == "")||(teacher == "")) {
            toast.warning("Veuillez remplir tout le formulaire avant de poursuivre !", { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast1' });
        }
        else
        {
            setLoader(true);

            try{
                const data_post = await axios.post(process.env.REACT_APP_API_KEY+"/course", {is_training, is_sensibilization, is_presential, title, short_description, content, file, teacher, is_pdf, is_audio, is_video}, {
                  headers: {
                    Authorization: loggedUser.token
                  }
                });

                setTitle("");
                setShortDescription("");
                setContent("");

                setLoader(false);

                toast.success(data_post.data.message, { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast1' });
            }
            catch(err) {
                setLoader(false);
                toast.error("Une erreur est survenue : "+err.response.data.message, { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast1' });
            }

        }

    }

    return(
        
        <>
            <div className="top-section">.</div>

            {loggedUser && loggedUser.is_admin === true ? 

            	<section aria-label="section">
                    <div className="container">
                        <div className="row">

                            <div id="comment-form-wrapper">

                                {activity_type === 'sensibilize' ?
                                    <h4 className="form-title">Ajouter une sensibilisation</h4>
                                    : <h4 className="form-title">Ajouter une formation</h4>
                                }
                                <br/>

                                <div className="form-default">
                                    <label>Titre <span className="req">*</span></label>
                                    <input type="text" className="input" value={ title } onChange={ e=>setTitle(e.target.value) } />
                                    <label>Formateur(trice) <span className="req">*</span></label>
                                    <select className="input" onChange={ e=>setTeacher(e.target.value) } >
                                        <option value=""> -- Veuillez choisir --</option>

                                        {teachers_list?.map((trainer)=>(
                                            <option value={ trainer._id }> { trainer.first_name } { trainer.last_name } </option>
                                        ))}

                                    </select>

                                    {activity_type === 'sensibilize' ? '' : 
                                        <>
                                            <label>Type de formation <span className="req">*</span></label>
                                            <select className="input" onChange={ e=>setPresential(e.target.value) } >
                                                <option value=""> -- Veuillez choisir --</option>
                                                <option value="true"> En Présentiel</option>
                                                <option value="false"> En Distentiel</option>
                                            </select>
                                        </>

                                    }

                                    <label>Fichier <span className="req">*</span></label>
                                    <input type="file" className="input" onChange={ handleFileUpload } />
                                    <br/>
                                    <label>Brève description <span className="req">*</span></label>
                                    <textarea cols="10" rows="4" className="area-input" value={ short_description }  onChange={ e=>setShortDescription(e.target.value) } ></textarea>
                                    <br/>
                                    <label>Contenu <span className="req">*</span></label>
                                    {/*<textarea cols="10" rows="10" className="area-input" value={ content }  onChange={ e=>setContent(e.target.value) } ></textarea>*/}
                                    
                                    <CKeditor
                                        name="content"
                                        onChange={(content) => {
                                          setContent(content);
                                        }}
                                        editorLoaded={editorLoaded}
                                    />
                                    <br/>
                                    
                                    {load === true ?
                                        <a className="posting-load">
                                            <Circles 
                                                height="40"
                                                width="40"
                                                color="#000"
                                                ariaLabel="circles-loading"
                                                wrapperStyle={{}}
                                                wrapperclass=""
                                                visible={true}
                                            />
                                        </a> : <button className="btn btn-custom" onClick={ postData }>Poster</button>   
                                        
                                    }

                                </div>

                            </div>
                            
                        </div>
                    </div>
                </section>   

            :

                <div className="kode-content">
                    <div className="kode-about-us-section">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    Vous n'êtes pas autorisé(e) à accéder à cette section !
                                </div> 
                            </div>
                        </div> 
                    </div>
                </div> 
                        
            }
        </>

    )
}