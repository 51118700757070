import './trainingdetails.css';

import axios from 'axios';
import Swal from 'sweetalert2';
import { useEffect, useState } from 'react';
import parse from 'html-react-parser';
import { toast } from 'react-toastify';
import { Link } from "react-router-dom";
import { useSelector } from 'react-redux';
import { Circles, TailSpin, RotatingLines } from  'react-loader-spinner';

export default function TrainingDetailsCpnt({ training_id }) {

	const [load, setLoader] = useState('');
    const [loadTraining, setLoadTraining] = useState("true");
	const [trainingData, setTrainingData] = useState([]);
	const [trainingDataImage, setTrainingDataImage] = useState([]);
    const [ image, setUploadedFilePath ] = useState("");
    const [showNoData, setShowNoData] = useState("false");

	const loggedUser = useSelector(state=>state.user.user);

	useEffect(()=> {

		const getTrainingData = async()=> {

			try
			{
				const training_data = await axios.get(process.env.REACT_APP_API_KEY+'/course/'+training_id);
				setTrainingData(training_data.data);

				setLoadTraining("false");
			}
			catch(err)
			{
				setShowNoData("true");
				toast.error(err.response.data, { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast1' });
			}
		}

		const getTrainingDataImages = async()=> {

			try
			{
				const training_data_image = await axios.get(process.env.REACT_APP_API_KEY+'/course-images/'+training_id);
				setTrainingDataImage(training_data_image.data);
			}
			catch(err)
			{
				toast.error(err.response.data, { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast1' });
			}
		}

		// functions call

		getTrainingData();
		getTrainingDataImages();

	}, []);

	const handleFileUpload = async(event) => {

        const current_file = event.target.files[0];
        const formData = new FormData();
        formData.append('file', current_file);

        try {
            const fileUploadData = await axios.post(process.env.REACT_APP_API_KEY+'/law-database/file-upload/', formData, {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            });
            setUploadedFilePath(fileUploadData.data.uploaded_file);
        } catch (error) {
            toast.error("Erreur lors du téléchargement du fichier : "+error, { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast1' });
        }

    };

    const postImage = async(e) => {
        e.preventDefault();

        if((image == "")) {
            toast.warning("Veuillez télécharger l'image pour poursuivre !", { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast1' });
        }
        else
        {
        	const course = training_id;
            setLoader(true);

            try{

                const course_image_post = await axios.post(process.env.REACT_APP_API_KEY+"/course-images", {image, course}, {
                    headers: {
                        Authorization: loggedUser.token
                    }
                });

                setUploadedFilePath("");

                setLoader(false);

                toast.success("Image ajoutée avec succès !", { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast1' });
            }
            catch(err) {
                setLoader(false);
                toast.error("Une erreur est survenue : "+err.response.data.message, { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast1' });
            }

        }

    }

    const removeTraining = (training_id, training_name)=> {

		Swal.fire({
		  title: 'Effacer '+training_name,
		  text: "Vous ne pourrez pas revenir en arrière !",
		  icon: 'warning',
		  showCancelButton: true,
		  confirmButtonColor: '#3085d6',
		  cancelButtonColor: '#d33',
		  confirmButtonText: 'Oui, effacer !',
		  cancelButtonText: 'Non !'
		}).then((result) => {
		  if (result.isConfirmed) {

		    deleteTraining(training_id);

		  }
		})

	}

	const deleteTraining = async(training_id) => {

		toast.info("Opération en cours !", { position: toast.POSITION.TOP_RIGHT, autoClose: 5000, toastId: 'toast2' });

        try{
        	const training_delete_data = await axios.put(process.env.REACT_APP_API_KEY+"/course/delete/"+training_id, {}, {
                headers: {
                    Authorization: loggedUser.token
                }
            });

            setShowNoData("true");

            toast.success(training_delete_data.data.message, { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast1' });
        }
        catch(err) {
            toast.error("Une erreur est survenue : "+err.response.data.message, { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast1' });
        }

    }

	return (

		<section aria-label="section">
            <div className="container">

	            <div className="row">

	            	{loggedUser && loggedUser.is_admin === true ? 

	            		<>
		            		<div className="add-image-section">
		            			<div className="add-image-field">
		            				<h4>Ajouter Une Image : </h4>
		            			</div>
		            			<div className="add-image-file-field">
	                            	<input type="file" onChange={ handleFileUpload } />
	                            </div>

	                            {load === true ?
	                                <a className="posting-load">
	                                    <Circles 
	                                        height="40"
	                                        width="40"
	                                        color="#000"
	                                        ariaLabel="circles-loading"
	                                        wrapperStyle={{}}
	                                        wrapperclass=""
	                                        visible={true}
	                                        />
	                                </a> : <button className="read-more black-button" onClick={ postImage }>Ajouter</button>   
	                                            
	                            }
	                        </div>

	                        <section className="text-light search-section">
	                            <div className="container">
	                                <div className="row align-items-center">

	                                	<div className="col-md-2 text-lg-right text-sm-center">
		                                    <Link to={`/editer-activiter/?param=${ trainingData._id}`} className="clear-button">Editer </Link>
		                                </div>
		                                <div className="col-md-2 text-lg-right text-sm-center">
		                                    <button className="danger-button" onClick={ e=>removeTraining(trainingData._id, trainingData.title) }>Effacer </button>
		                                </div>

	                                </div>
	                            </div>
	                        </section>
                        </>

                    : "" }

	                {/*<div className="col-md-4">
	                	{ userData.profile_image ? <img className="profile-image" src={ userData.profile_image } alt="" /> : <img src="/images/misc/avatar.png" alt="" /> }
	                </div>*/}


	                {showNoData === 'true' ? <div className="no-data">Données supprimées</div> : 

		               	<div className="col-md-12">

			            	{loadTraining === 'true' ? 

				                <div className="data-loader">

				                    <TailSpin 
				                        height="40"
				                        width="40"
				                        color="#000"
				                        ariaLabel="tail-spin-loading"
				                        radius="1"
				                        wrapperStyle={{}}
				                        wrapperClass=""
				                        visible={true}
				                    />

				                </div>

				                : "" 
				            }

		                	<h2 className="c_name"> 
		                		{ trainingData.title } 

		                		{ trainingData.is_presential === true ? 
		                			<> { trainingData.is_training === true ? <i>(En présentiel)</i> : <i>(En distentiel)</i> } </>
		                		: '' }
		                	</h2>
		                	<br/><br/>
		                    <div className="comment">
		                    	{typeof trainingData.content === 'string' ? parse(trainingData.content) : null}

		                    	{ trainingData.file ? <a href={trainingData.file} target="_blank" className="btn-custom read-more">Télécharger</a> : "" }
		                    </div>

		                    <div className="no-bottom no-top" id="content">

					            <section aria-label="section">
					                <div className="container">
					                    <div className="row">

					                    	{trainingDataImage?.map((image)=> (

						                        <div className="col-md-4 mb30">
						                            <div className="de-image-hover">
						                                <a href={image.image} className="image-popup">
						                                    {/*<span className="dih-title-wrap">
						                                    	<span className="dih-title">Gallery Title</span>
						                                    </span>*/}
						                                    <span className="dih-overlay"></span>
						                                    <img src={image.image} className="img-fluid" alt=""/>
						                                </a>
						                            </div>
						                        </div>

					                        ))}

					                    </div>
					                </div>
					            </section>
					        </div>

		                </div>

		            }

	            </div>

            </div>
        </section>
        
	)

}
