import './newsdetails.css';

import axios from 'axios';
import parse from 'html-react-parser';
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { toast } from 'react-toastify';
import { Circles } from  'react-loader-spinner';
import { useSelector } from 'react-redux';

import NewsData from '../../components/newsdata/newsdata';

export default function NewsDetails() {

    const queryParams = new URLSearchParams(window.location.search);
    const article = queryParams.get('param');

    const loggedUser = useSelector(state=>state.user.user);

    return(
        
        <>
            <div className="top-section">.</div>


            	<section aria-label="section">
                    <div className="container">
                        <div className="row">

                            <NewsData article_id={ article } />
                            
                        </div>
                    </div>
                </section>   

        </>

    )
}