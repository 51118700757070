import './news.css';

import axios from 'axios';
import { useEffect, useState } from 'react';
import parse from 'html-react-parser';
import { toast } from 'react-toastify';
import { Link } from "react-router-dom";
import moment from "moment";
import { TailSpin, RotatingLines } from  'react-loader-spinner';

export default function News({ search_term, category, limit }) {

    const [newsData, setNewsData] = useState([]);
    const [loadArticles, setLoadArticles] = useState("true");

    useEffect(()=> {

        const getNewsData = async()=> {

            try {
                const articles = await axios.get(process.env.REACT_APP_API_KEY+'/article/?limit='+limit+'&category='+category+'&search_term='+search_term);
                setNewsData(articles.data);

                setLoadArticles("false");
            }
            catch(err) {
                toast.error(err.response.data, { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast1' });
            }
        }

        // functions call

        getNewsData();
        
    });

	return (

    		<section aria-label="section" className="background">

                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="text-center">
                                <h2 className="title-padding">Actualités</h2>
                                <div className="small-border"></div>
                            </div>
                        </div>
                    </div>

                    {loadArticles === 'true' ? 

                        <div className="data-loader">

                            <TailSpin 
                                height="40"
                                width="40"
                                color="#000"
                                ariaLabel="tail-spin-loading"
                                radius="1"
                                wrapperStyle={{}}
                                wrapperClass=""
                                visible={true}
                            />

                        </div>

                        : "" 
                    }  

                    {newsData.length === 0 ? <p>Pas d'articles pour le moment !</p> :

                        <div className="row">

                            {Array.isArray(newsData) && newsData.map((news) => (

                                <div className="col-lg-4 col-md-6 mb30">
                                    <div className="bloglist item">
                                        <div className="post-content">
                                            <div className="date-box">
                                                <div className="m">{moment(news.createdAt).format('MMMM Do YYYY, h:mm:ss a')}</div>
                                                {/*<div className="d">NOV</div>*/}
                                            </div>
                                            <div className="post-image">
                                                <img alt="" src={news.image}/>
                                            </div>
                                            <div className="post-text">
                                                <span className="p-tagline">{news.category && news.category.category}</span>
                                                <h4><Link to={{pathname: '/article-details/', search: '?param='+news._id}}>{news.title}<span></span></Link></h4>
                                                <p>{parse(news.article.substring(0, 130)+ ' ...')}</p>
                                                <span className="p-author">{news.added_by && news.added_by.first_name} {news.added_by && news.added_by.last_name}</span>
                                                <Link className="read-more" to={{pathname: '/article-details/', search: '?param='+news._id}}>Lire Plus</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            ))}

                        </div>
                    }
                </div>
            </section>
        
	    )

}