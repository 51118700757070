import './expertisescategoriescpnt.css';

import axios from 'axios';
import parse from 'html-react-parser';
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { TailSpin, LineWave, RotatingLines } from  'react-loader-spinner';

export default function ExpertiseCatCpnt() {

    const [load, setLoader] = useState('true');
    const [expertises_categories_data, setExpertisesCategoriesData] = useState([]);

    const loggedUser = useSelector(state=>state.user.user);

    useEffect(()=>{

        const loadExpertisesCategories = async()=> {

            try{
                const expertisesCategoriesRes = await axios.get(process.env.REACT_APP_API_KEY+"/expertise-categories/");
                setExpertisesCategoriesData(expertisesCategoriesRes.data);

                setLoader('false');
            }
            catch(err) {
                setLoader('false');
                toast.error(err.response.data, { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast1' });
            }
        }

        // functions call 

        loadExpertisesCategories();

    }, []);

    return(
        
        <>

            {load === 'true' ? 

                <div className="data-loader">

                    <TailSpin
                        height="40"
                        width="40"
                        color="#000"
                        ariaLabel="tail-spin-loading"
                        radius="1"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                    />

                </div>

                : "" 
            }

            {expertises_categories_data?.map((expertise_cat)=> (

                <div className="col-lg-6 col-md-6 col-sm-6 mb30 pull-left clear">
                    <div className="expertise-list">

                        {loggedUser && loggedUser.is_admin === true ? 

                            <Link to={`/editer-category-d-expertise/?param=${ expertise_cat._id}`}><button className="edit-button">Editer <i class="fa fa-pencil" aria-hidden="true"></i></button></Link>

                        : "" }

                        <div className="details">
                            <h4 className="expertise-h2">{expertise_cat.category}</h4>
                            <p className="expertise-p">{expertise_cat.short_description}</p>
                        </div>
                        <div className="view-button">
                            <Link className="voir-experts" to={{pathname: '/nos-domaines/', search: '?category='+expertise_cat._id}}><button><i className="fa fa-arrow-right"></i></button></Link>
                            {/*<Link className="voir-experts" to={{pathname: '/membre/', search: '?expert=true&expert='+expertise_cat._id}}><button><i className="fa fa-arrow-right"></i></button></Link>*/}
                        </div>

                    </div>
                </div>

            ))} 
        </>

    )
}