import './training.css';

import axios from 'axios';
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { toast } from 'react-toastify';
import parse from 'html-react-parser';
import { TailSpin, RotatingLines } from  'react-loader-spinner';

export default function TrainingCpnt({ expert, training_type, domain, search_domain, search_term }) {

    let set_domain = '';

	const [training_data, setTrainingData] = useState([]);
    const [loadTraining, setLoadTraining] = useState("true");

    if((domain == null)&&(search_domain == null)) {
        set_domain = '';
    }
    else if((domain == null)&&(search_domain != null)) {
        set_domain = search_domain;
    }
    else if((domain != null)&&(search_domain == null)) {
        set_domain = domain;
    }
    else
    {
        set_domain = search_domain;
    }

	useEffect(()=>{

        const getTrainings = async()=> {

            try{                    
                const trainingsRes = await axios.get(process.env.REACT_APP_API_KEY+"/course/?training_type="+training_type+"&domain="+set_domain+'&search_term='+search_term);
                setTrainingData(trainingsRes.data);

                setLoadTraining("false");
            }
            catch(err) {
                toast.error(err.response.data, { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast1' });
            }
        }

        // functions call 

        getTrainings();

    });

	return(

		<>
            {loadTraining === 'true' ? 

                <div className="data-loader">

                    <TailSpin 
                        height="40"
                        width="40"
                        color="#000"
                        ariaLabel="tail-spin-loading"
                        radius="1"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                    />

                </div>

                : "" 
            }

			{training_data?.map((course)=> (

                <div className="col-lg-6 col-md-6 col-sm-6 mb30 pull-left clear">
    				<div className="trainings-list">

                        <div className="details">
                            <h4 className="training-details-h2">{course.title}</h4>
                            <p className="training-details-p">{parse(course.content.substring(0, 130)+ '...')}</p>
                            {/*<a href=""><button className="view-button-more">Voir le contenu</button></a>*/}
                            <Link className="view-button-more details-button" to={{pathname: '/activite-details/', search: '?training='+course._id}}>Voir le contenu</Link>
                            
                        </div>

                    </div>
                </div>

            ))}
		</>
	)

}