import './editnews.css';

import axios from 'axios';
import parse from 'html-react-parser';
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { Circles } from  'react-loader-spinner';
import CKeditor from '../../components/CKeditor';

import { expertiseDataFetchSuccess } from '../../redux/expertisesSlice';

export default function EditNews() {

    const queryParams = new URLSearchParams(window.location.search);
    const param = queryParams.get('param');

    const loggedUser = useSelector(state=>state.user.user);

    const [ id, setUserId ] = useState("");
    const [ category, setCategory ] = useState("");
    const [ current_category, setCurrentCategory ] = useState([]);
    const [ title, setTitle ] = useState("");
    const [ image, setUploadedFilePath ] = useState("");
    const [ article, setArticle ] = useState("");
    const [ categories, setCategoriesData ] = useState([]);

    const [editorLoaded, setEditorLoaded] = useState(false);
    const [load, setLoader] = useState('');

    const expertisesSelector = useSelector(expertiseDataFetchSuccess);

    useEffect(()=> {

        setUserId(param);
        setEditorLoaded(true);

        const getCategories = async()=> {

            try{
                const categoriesRes = await axios.get(process.env.REACT_APP_API_KEY+"/article-categories/");
                setCategoriesData(categoriesRes.data);
            }
            catch(err) {
                toast.error("Erreur de serveur : les catégories n'ont pas été récupérées !", { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast1' });
            }
        }

        const getArticleData = async()=> {
            const article_data = await axios.get(process.env.REACT_APP_API_KEY+'/article/'+param);

            setTitle(article_data.data.title);
            setUploadedFilePath(article_data.data.file);
            setCategory(article_data.data.category._id);
            setCurrentCategory(article_data.data.category);
            setArticle(article_data.data.article);

        }

        // functions call

        getArticleData();
        getCategories();

    }, []);

    const handleFileUpload = async(event) => {

        const current_file = event.target.files[0];
        const formData = new FormData();
        formData.append('file', current_file);

        try {
            const fileUploadData = await axios.post(process.env.REACT_APP_API_KEY+'/law-database/file-upload/', formData, {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            });
            setUploadedFilePath(fileUploadData.data.uploaded_file);
        } catch (error) {
            toast.error("Erreur lors du téléchargement du fichier : "+error, { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast1' });
        }

    };

    const postData = async(e) => {
        e.preventDefault();

        if((category == "")||(title == "")||(article == "")||(image == "")) {
            toast.warning("Veuillez remplir tout le formulaire avant de poursuivre !", { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast1' });
        }
        else
        {
            setLoader(true);

            try{

                const data_post = await axios.post(process.env.REACT_APP_API_KEY+"/article/"+param, {id, category, title, article, image}, {
                    headers: {
                        Authorization: loggedUser.token
                    }
                });

                setTitle(data_post.data.title);
                setUploadedFilePath(data_post.data.file);
                setCategory(data_post.data.category._id);
                setCurrentCategory(data_post.data.category);
                setArticle(data_post.data.article);

                setLoader(false);

                toast.success("Article mis à jour avec succès !", { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast1' });
            }
            catch(err) {
                setLoader(false);
                toast.error("Une erreur est survenue : "+err.response.data.message, { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast1' });
            }

        }

    }

    return(
        
        <>
            <div className="top-section">.</div>

            {loggedUser ?

            	<section aria-label="section">

                    {loggedUser.slug === param || loggedUser.is_admin === true ? 

                        <div className="container">
                            <div className="row">

                                <div className="col-md-8">

                                    <h4 className="form-title">Editer </h4>
                                    <br/>

                                    <div className="form-card">
                                        <label>Catégorie <span className="req">*</span></label>
                                        <select className="input" onChange={ e=>setCategory(e.target.value) } >
                                            <option value={ current_category._id }> { current_category.category } </option>
                                            {categories?.map((cat)=> (
                                                <option value={ cat._id }> { cat.category } </option>
                                            ))}
                                        </select>
                                        <label>Titre <span className="req">*</span></label>
                                        <input type="text" className="input" value={ title } onChange={ e=>setTitle(e.target.value) } />
                                        <label>Fichier </label>
                                        <input type="file" className="input" onChange={ handleFileUpload } />
                                        <br/>
                                        <label>Article <span className="req">*</span></label>
                                        {/*<textarea cols="10" rows="10" className="area-input" value={ article }  onChange={ e=>setArticle(e.target.value) } ></textarea>*/}
                                        
                                        <CKeditor
                                            name="article"
                                            onChange={(article) => {
                                              setArticle(article);
                                            }} value={article}
                                            editorLoaded={editorLoaded}
                                        />
                                        <br/>

                                        {load === true ?
                                            <a className="posting-load">
                                                <Circles 
                                                    height="50"
                                                    width="50"
                                                    color="#000"
                                                    ariaLabel="circles-loading"
                                                    wrapperStyle={{}}
                                                    wrapperClass=""
                                                    visible={true}
                                                />
                                            </a> : <button className="btn btn-custom" onClick={ postData }>Mettre à jour</button>   
                                            
                                        }

                                    </div>

                                </div>
                                
                            </div>
                        </div>

                    :

                        <div className="kode-content">
                            <div className="kode-about-us-section">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-md-6 success-message">
                                            Vous n'êtes pas connecté(e) ! Veuillez vous connecter pour acceder cette section !
                                        </div> 
                                    </div>
                                </div> 
                            </div>
                        </div> 
                        
                    }

                </section>   

            :

                <div className="kode-content">
                    <section className="kode-about-us-section">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-6 success-message">
                                    Vous n'êtes pas connecté(e) ! Veuillez vous connecter pour acceder cette section !
                                </div> 
                            </div>
                        </div> 
                    </section>
                </div> 
                
            }

        </>

    )
}