import './editcompany.css';

import axios from 'axios';
import parse from 'html-react-parser';
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { Circles } from  'react-loader-spinner';
import CKeditor from '../../components/CKeditor';

import { expertiseDataFetchSuccess } from '../../redux/expertisesSlice';

export default function EditCompany() {

    const queryParams = new URLSearchParams(window.location.search);
    const param = queryParams.get('param');

    const loggedUser = useSelector(state=>state.user.user);

    const [ id, setCompanyId ] = useState("");
    const [ name, setCompanyName ] = useState("");
    const [ slogan, setCompanySlogan ] = useState("");
    const [ short_description, setCompanyShortDescription ] = useState("");
    const [ description, setCompanyDescription ] = useState("");
    const [ email, setCompanyEmail ] = useState("");
    const [ tel, setCompanyTel ] = useState("");
    const [ address, setCompanyAdress ] = useState("");
    const [ logo, setUploadedFilePath ] = useState("");
    const [ mission, setCompanyMission ] = useState("");
    const [ vision, setCompanyVision ] = useState("");
    const [ objective, setCompanyObjective ] = useState("");
    const [ director_words, setCompanyDirectorWords ] = useState("");
    const [ welcome_image, setUploadedWelcomeImageFilePath ] = useState("");
    const [ facebook_url, setCompanyFacebookUrl ] = useState("");
    const [ twitter_url, setCompanyTwitterUrl ] = useState("");
    const [ instagram_url, setCompanyInstagramUrl ] = useState("");

    const [editorLoaded, setEditorLoaded] = useState(false);
    const [secondEditorLoaded, setSecondEditorLoaded] = useState(false);
    const [load, setLoader] = useState('');

    useEffect(()=> {

        setCompanyId(param);
        setEditorLoaded(true);
        setSecondEditorLoaded(true);

        const getCompanyData = async()=> {

            try {
                const requested_company_data = await axios.get(process.env.REACT_APP_API_KEY+'/company/'+param);
                
                setCompanyName(requested_company_data.data[0].name);
                setCompanySlogan(requested_company_data.data[0].slogan);
                setCompanyShortDescription(requested_company_data.data[0].short_description);
                setCompanyDescription(requested_company_data.data[0].description);
                setCompanyEmail(requested_company_data.data[0].email);
                setCompanyTel(requested_company_data.data[0].tel);
                setCompanyAdress(requested_company_data.data[0].address);
                setUploadedFilePath(requested_company_data.data[0].logo);
                setCompanyMission(requested_company_data.data[0].mission);
                setCompanyVision(requested_company_data.data[0].vision);
                setCompanyObjective(requested_company_data.data[0].objective);
                setCompanyDirectorWords(requested_company_data.data[0].director_words);
                setUploadedWelcomeImageFilePath(requested_company_data.data[0].welcome_image);
                setCompanyFacebookUrl(requested_company_data.data[0].facebook_url);
                setCompanyTwitterUrl(requested_company_data.data[0].twitter_url);
                setCompanyInstagramUrl(requested_company_data.data[0].instagram_url);
                

            } catch (error) {
                toast.error(error.response.data.message, { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast6' });
            }

        }

        // functions call

        getCompanyData();

    }, []);

    const handleFileUpload = async(event) => {

        const current_file = event.target.files[0];
        const formData = new FormData();
        formData.append('file', current_file);

        try {
            const fileUploadData = await axios.post(process.env.REACT_APP_API_KEY+'/law-database/file-upload/', formData, {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            });
            setUploadedFilePath(fileUploadData.data.uploaded_file);
        } catch (error) {
            toast.error("Erreur lors du téléchargement du fichier : "+error, { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast1' });
        }

    };

    const handleWelcomeImageFileUpload = async(event) => {

        const current_welcome_image_file = event.target.files[0];
        const welcomeImageFormData = new FormData();
        welcomeImageFormData.append('file', current_welcome_image_file);

        try {
            const welcomeImageFileUploadData = await axios.post(process.env.REACT_APP_API_KEY+'/law-database/file-upload/', welcomeImageFormData, {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            });
            
            setUploadedWelcomeImageFilePath(welcomeImageFileUploadData.data.uploaded_file);
        } catch (error) {
            toast.error("Erreur lors du téléchargement du fichier : "+error, { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast1' });
        }

    };

    const postData = async(e) => {
        e.preventDefault();

        if((name == "")||(slogan == "")||(short_description == "")||(description == "")||(email == "")||(tel == "")||(address == "")||(logo == "")||(mission == "")||(vision == "")||(objective == "")||(director_words == "")||(welcome_image == "")) {
            toast.warning("Veuillez remplir tout le formulaire avant de poursuivre !", { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast1' });
        }
        else
        {
            setLoader(true);

        alert(welcome_image);

            try{

                const data_post = await axios.post(process.env.REACT_APP_API_KEY+"/company/"+param, {id, name, slogan, short_description, description, email, tel, address, logo, mission, vision, objective, director_words, welcome_image, facebook_url, twitter_url, instagram_url}, {
                    headers: {
                        Authorization: loggedUser.token
                    }
                });

                setCompanyName(data_post.data.name);
                setCompanySlogan(data_post.data.slogan);
                setCompanyShortDescription(data_post.data.short_description);
                setCompanyDescription(data_post.data.description);
                setCompanyEmail(data_post.data.email);
                setCompanyTel(data_post.data.tel);
                setCompanyAdress(data_post.data.address);
                setUploadedFilePath(data_post.data.logo);
                setCompanyMission(data_post.data.mission);
                setCompanyVision(data_post.data.vision);
                setCompanyObjective(data_post.data.objective);
                setCompanyDirectorWords(data_post.data.director_words);
                setUploadedWelcomeImageFilePath(data_post.data.welcome_image);
                setCompanyFacebookUrl(data_post.data.facebook_url);
                setCompanyTwitterUrl(data_post.data.twitter_url);
                setCompanyInstagramUrl(data_post.data.instagram_url);

                setLoader(false);

                toast.success("Donnée mis à jour avec succès !", { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast1' });
            }
            catch(err) {
                setLoader(false);
                toast.error("Une erreur est survenue : "+err.response.data.message, { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast1' });
            }

        }

    }

    return(
        
        <>
            <div className="top-section">.</div>

            {loggedUser ?

            	<section aria-label="section">

                    {loggedUser.slug === param || loggedUser.is_admin === true ? 

                        <div className="container">
                            <div className="row">

                                <div className="col-md-8">

                                    <h4 className="form-title">Editer </h4>
                                    <br/>

                                    <div className="form-card">
                                        <label>Nom <span className="req">*</span></label>
                                        <input type="text" className="input" value={ name } onChange={ e=>setCompanyName(e.target.value) } />
                                        <label>Slogan <span className="req">*</span></label>
                                        <input type="text" className="input" value={ slogan } onChange={ e=>setCompanySlogan(e.target.value) } />
                                        <label>Email <span className="req">*</span></label>
                                        <input type="email" className="input" value={ email } onChange={ e=>setCompanyEmail(e.target.value) } />
                                        <label>Numéro de téléphone <span className="req">*</span></label>
                                        <input type="text" className="input" value={ tel } onChange={ e=>setCompanyTel(e.target.value) } />
                                        <label>Adresse <span className="req">*</span></label>
                                        <input type="text" className="input" value={ address } onChange={ e=>setCompanyAdress(e.target.value) } />
                                        <label>Mission <span className="req">*</span></label>
                                        <input type="text" className="input" value={ mission } onChange={ e=>setCompanyMission(e.target.value) } />
                                        <label>Vision <span className="req">*</span></label>
                                        <input type="text" className="input" value={ vision } onChange={ e=>setCompanyVision(e.target.value) } />
                                        <br/>

                                        <label>Objectifs <span className="req">*</span></label>
                                        <CKeditor
                                            name="objective" 
                                            onChange={(objective) => {
                                                setCompanyObjective(objective);
                                            }} value={objective}
                                            editorLoaded={secondEditorLoaded}
                                        />
                                        <br/>

                                        <label>URL du profil Facebook <span className="req">*</span></label>
                                        <input type="text" className="input" value={ facebook_url } onChange={ e=>setCompanyFacebookUrl(e.target.value) } />
                                        <label>URL du profil Twitter <span className="req">*</span></label>
                                        <input type="text" className="input" value={ twitter_url } onChange={ e=>setCompanyTwitterUrl(e.target.value) } />
                                        <label>URL du profil Instagram <span className="req">*</span></label>
                                        <input type="text" className="input" value={ instagram_url } onChange={ e=>setCompanyInstagramUrl(e.target.value) } />
                                        <label>Logo </label>
                                        <input type="file" className="input" onChange={ handleFileUpload } />
                                        <br/>
                                        <label>Image de bienvenue </label>
                                        <input type="file" className="input" onChange={ handleWelcomeImageFileUpload } />
                                        <br/>
                                        <label>Brève description <span className="req">*</span></label>
                                        <input type="text" className="input" value={ short_description } onChange={ e=>setCompanyShortDescription(e.target.value) } />
                                        <label>Description <span className="req">*</span></label>
                                        <CKeditor
                                                name="description" 
                                                onChange={(description) => {
                                                    setCompanyDescription(description);
                                                }} value={description}
                                                editorLoaded={editorLoaded}
                                        />
                                        <br/>
                                        
                                        {load === true ?
                                            <a className="posting-load">
                                                <Circles 
                                                    height="50"
                                                    width="50"
                                                    color="#000"
                                                    ariaLabel="circles-loading"
                                                    wrapperStyle={{}}
                                                    wrapperClass=""
                                                    visible={true}
                                                />
                                            </a> : <button className="btn btn-custom" onClick={ postData }>Mettre à jour</button>   
                                            
                                        }

                                    </div>

                                </div>
                                
                            </div>
                        </div>

                    :

                        <div className="kode-content">
                            <div className="kode-about-us-section">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-md-6 success-message">
                                            Vous n'êtes pas connecté(e) ! Veuillez vous connecter pour acceder cette section !
                                        </div> 
                                    </div>
                                </div> 
                            </div>
                        </div> 
                        
                    }

                </section>   

            :

                <div className="kode-content">
                    <section className="kode-about-us-section">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-6 success-message">
                                    Vous n'êtes pas connecté(e) ! Veuillez vous connecter pour acceder cette section !
                                </div> 
                            </div>
                        </div> 
                    </section>
                </div> 
                
            }

        </>

    )
}