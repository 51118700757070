import './legalkitfiles.css';

import axios from 'axios';
import Swal from 'sweetalert2';
import { useEffect, useState } from 'react';
import parse from 'html-react-parser';
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { TailSpin, RotatingLines } from  'react-loader-spinner';

export default function LegalKitFiles({ legal_kit_id }) {

	const [legalKitFilesData, setLegalKitFilesData] = useState([]);
    const [loadLegalKitFiles, setLoadLegalKitFiles] = useState("true");
    const [showNoData, setShowNoData] = useState("false");

	const loggedUser = useSelector(state=>state.user.user);

	useEffect(()=> {

		const getLegalKitFilesData = async()=> {
			try {
				const legal_kit_files_data = await axios.get(process.env.REACT_APP_API_KEY+'/legal-kit-files/'+legal_kit_id);
				setLegalKitFilesData(legal_kit_files_data.data);

				setLoadLegalKitFiles("false");
				setShowNoData("true");
			}
			catch(err) {
				toast.error(err.response.data, { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast1' });
			}
		}

		// functions call

		getLegalKitFilesData();

	});

	const removeLegalKitFile = (legalKit_file_id, legalKit_file_title)=> {

		Swal.fire({
		  title: 'Effacer le document '+legalKit_file_title,
		  text: "Vous ne pourrez pas revenir en arrière !",
		  icon: 'warning',
		  showCancelButton: true,
		  confirmButtonColor: '#3085d6',
		  cancelButtonColor: '#d33',
		  confirmButtonText: 'Oui, effacer !',
		  cancelButtonText: 'Non !'
		}).then((result) => {
		  if (result.isConfirmed) {

		    deleteLegalKit(legalKit_file_id);

		  }
		})

	}

	const deleteLegalKit = async(legal_kit_file_id) => {

		toast.info("Opération en cours !", { position: toast.POSITION.TOP_RIGHT, autoClose: 5000, toastId: 'toast2' });

        try{
        	const legal_kit_document_post = await axios.put(process.env.REACT_APP_API_KEY+"/legal-kit-files/delete/"+legal_kit_file_id, {}, {
                headers: {
                    Authorization: loggedUser.token
                }
            });

            setShowNoData("true");

            toast.success(legal_kit_document_post.data.message, { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast1' });
        }
        catch(err) {
            toast.error("Une erreur est survenue : "+err.response.data.message, { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast1' });
        }

    }

	return (

		<div className="legal-kit-file-list">
            <h3>Documents </h3>
            <div className="small-border border-up"></div>
            <br/>

            {loadLegalKitFiles === 'true' ? 

            	<TailSpin 
            		height="40"
            		width="40"
            		color="#000"
            		ariaLabel="tail-spin-loading"
            		radius="1"
            		wrapperStyle={{}}
            		wrapperClass=""
            		visible={true}
            	/>

            	: "" 
            }

            {showNoData === 'true' ? <div className="no-data">Données supprimées</div> : 

	            <ul>
	            	{legalKitFilesData?.map((file)=> (
	            		<li>
	            			<a href={file.file}>{ file.title }</a>
	                        {loggedUser && loggedUser.is_admin === true ? <div className="remove-expertise remove-kit" onClick={ e=>removeLegalKitFile(file._id, file.title) }>X</div> : '' }
	                    </li>
	                ))}
	            </ul>

	        }

        </div>           
        
	)

}
