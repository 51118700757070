import './legalkits.css';

import axios from 'axios';
import { useEffect, useState } from 'react';
import parse from 'html-react-parser';
import Swal from 'sweetalert2';
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { TailSpin, RotatingLines } from  'react-loader-spinner';

export default function LegalKitCpnt({ search_term }) {

	const [legalKitData, setLegalKitData] = useState([]);
    const [loadLegalKits, setLoadLegalKits] = useState("true");

	const loggedUser = useSelector(state=>state.user.user);

	useEffect(()=> {

		const getLegalKitData = async()=> {
			try {
				const legal_kit_data = await axios.get(process.env.REACT_APP_API_KEY+'/legal-kit/?search_term='+search_term);
				setLegalKitData(legal_kit_data.data);

				setLoadLegalKits("false");
			}
			catch(err) {
				toast.error(err.response.data, { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast1' });
			}
		}

		// functions call

		getLegalKitData();

	});

	return (

		<section aria-label="section">
            <div className="container">
                <div className="row">

                	<div className="col-md-12">
                		<div className="comment-info">
                            <br/>
                            <h3>Kits juridiques </h3>

                            {loadLegalKits === 'true' ? 

				                <div className="data-loader">

				                    <TailSpin 
				                        height="40"
				                        width="40"
				                        color="#000"
				                        ariaLabel="tail-spin-loading"
				                        radius="1"
				                        wrapperStyle={{}}
				                        wrapperClass=""
				                        visible={true}
				                    />

				                </div>

				                : "" 
				            }

                            <div className="widget widget-post">
                                <ul>
                                	{legalKitData?.map((legalKit)=> (
                                		<li>
                                			<Link className="legal-kit-list" to={{pathname: '/kit-juridique-details/', search: '?param='+legalKit._id}}><span className="expert-expertise-list">{ legalKit.title }</span></Link>
                                        </li>
                                    ))}
                                </ul>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </section>
        
	)

}
