import './editrulesandreglementation.css';

import axios from 'axios';
import parse from 'html-react-parser';
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { Circles } from  'react-loader-spinner';
import CKeditor from '../../components/CKeditor';


export default function EditRulesAndRegulations() {

    const queryParams = new URLSearchParams(window.location.search);
    const param = queryParams.get('param');

    const loggedUser = useSelector(state=>state.user.user);

    const [ id, setRulesAndRegulationsId ] = useState("");
    const [ rules, setRulesAndRegulationsData ] = useState("");

    const [editorLoaded, setEditorLoaded] = useState(false);
    const [load, setLoader] = useState('');

    useEffect(()=> {

        setRulesAndRegulationsId(param);
        setEditorLoaded(true);

        const getRulesAndRegulationsData = async()=> {
            const rules_and_regulations_data = await axios.get(process.env.REACT_APP_API_KEY+'/rules-and-regulations/');
            setRulesAndRegulationsData(rules_and_regulations_data.data[0].rules);

        }

        // functions call

        getRulesAndRegulationsData();

    }, []);


    const postData = async(e) => {
        e.preventDefault();

        if((rules == "")) {
            toast.warning("Veuillez remplir tout le formulaire avant de poursuivre !", { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast1' });
        }
        else
        {
            setLoader(true);

            try{

                const data_post = await axios.post(process.env.REACT_APP_API_KEY+"/rules-and-regulations/"+param, {id, rules}, {
                    headers: {
                        Authorization: loggedUser.token
                    }
                });

                setRulesAndRegulationsData(data_post.data.rules);

                setLoader(false);

                toast.success("Politique de confidentialité mis à jour avec succès !", { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast1' });
            }
            catch(err) {
                setLoader(false);
                toast.error("Une erreur est survenue : "+err.response.data.message, { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast1' });
            }

        }

    }

    return(
        
        <>
            <div className="top-section">.</div>

            {loggedUser ?

            	<section aria-label="section">

                    {loggedUser.slug === param || loggedUser.is_admin === true ? 

                        <div className="container">
                            <div className="row">

                                <div className="col-md-8">

                                    <h4 className="form-title">Editer </h4>
                                    <br/>

                                    <div className="form-card">

                                        <CKeditor
                                            name="rules"
                                            onChange={(rules) => {
                                              setRulesAndRegulationsData(rules);
                                            }} value={rules}
                                            editorLoaded={editorLoaded}
                                        />


                                        <br/>

                                        {load === true ?
                                            <a className="posting-load">
                                                <Circles 
                                                    height="50"
                                                    width="50"
                                                    color="#000"
                                                    ariaLabel="circles-loading"
                                                    wrapperStyle={{}}
                                                    wrapperClass=""
                                                    visible={true}
                                                />
                                            </a> : <button className="btn btn-custom" onClick={ postData }>Mettre à jour</button>   
                                            
                                        }

                                    </div>

                                </div>
                                
                            </div>
                        </div>

                    :

                        <div className="kode-content">
                            <div className="kode-about-us-section">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-md-6 success-message">
                                            Vous n'êtes pas connecté(e) ! Veuillez vous connecter pour acceder cette section !
                                        </div> 
                                    </div>
                                </div> 
                            </div>
                        </div> 
                        
                    }

                </section>   

            :

                <div className="kode-content">
                    <section className="kode-about-us-section">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-6 success-message">
                                    Vous n'êtes pas connecté(e) ! Veuillez vous connecter pour acceder cette section !
                                </div> 
                            </div>
                        </div> 
                    </section>
                </div> 
                
            }

        </>

    )
}