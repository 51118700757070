import './singledata.css';

import axios from 'axios';
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { toast } from 'react-toastify';

export default function SingleData({ expert, domain }) {

	const [user_data, setUserData] = useState([]);

	useEffect(()=>{

        const getUsers = async()=> {

            if(expert==='true')
            {
                try{
                    const usersRes = await axios.get(process.env.REACT_APP_API_KEY+"/experts/?domain="+domain);
                    setUserData(usersRes.data);
                }
                catch(err) {
                    toast.error(err.response.data, { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast1' });
                }
            }
            else
            {
                try{
                    const usersRes = await axios.get(process.env.REACT_APP_API_KEY+"/users/");
                    setUserData(usersRes.data);
                }
                catch(err) {
                    toast.error(err.response.data, { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast1' });
                }
            }
        }

        // functions call 

        getUsers();

    }, []);

	return(

		<>
			{user_data?.map((user)=> (

                <>
    				<div className="col-lg-4 col-md-6 col-sm-6 mb30">
    					<div className="f-profile text-center">
    						<div className="fp-wrap f-invert">
    							<div className="fpw-overlay">
    								<div className="fpwo-wrap">
    									<div className="fpwow-icons">
    										<a href="{user.facebook_profile}" target="_blank"><i className="fa fa-facebook fa-lg"></i></a>
                                            <a href="{user.twitter_profile}" target="_blank"><i className="fa fa-twitter fa-lg"></i></a>
                                            <a href="{user.linkedin_profile}" target="_blank"><i className="fa fa-linkedin fa-lg"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <div className="fpw-overlay-btm"></div>
                                <img src={user.profile_image} className="fp-image img-fluid" alt=""/>
                            </div>
                            <h4>{user.first_name} {user.last_name}</h4>
                            {user.email}
                        </div>
                    </div>
                </>

            ))}
		</>
	)

}