import './lawdatabase.css';

import axios from 'axios';
import parse from 'html-react-parser';
import { Link, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { toast } from 'react-toastify';
import { useParams } from "react-router-dom";
import { useSelector } from 'react-redux';

import LawsRepository from '../../components/lawsrepository/lawsrepository';

export default function LawDatatabase() {

	// const queryParams = new URLSearchParams(window.location.search);
	// const law_category = queryParams.get('category');

    const location = useLocation();
    const { law_category } = location.state;

    const loggedUser = useSelector(state=>state.user.user);
    
    const [search_term, setSearchTerm] = useState("");

    return(
        
        <>
            <div className="top-section">.</div>
        	<section aria-label="section">
                <div className="container">
                    <div className="row">

                        <section className="text-light search-section">
                            <div className="container">
                                <div className="row align-items-center">
                                    <div className="col-md-8 mb-sm-30 text-lg-left text-sm-center">
                                        <input type="text" className="search-input" value={search_term} onChange={e=>setSearchTerm(e.target.value)} placeholder="Rechercher ..." />
                                    </div>

                                    {loggedUser && loggedUser?.is_admin === true ? 

                                        <div className="col-md-4 text-lg-right text-sm-center">
                                            <Link to="/ajouter-donnees" className="add-database">Ajouter une donnée</Link>
                                        </div>

                                    : "" }

                                </div>
                            </div>
                        </section>

                        {/*<div className="col-md-12 text-center">
                                                    <h2>Base de donnees</h2>
                                                    <div className="small-border"></div>
                                                </div> */}

                        <LawsRepository search_term={ search_term } category={ law_category } />

                    </div>
                </div>
            </section>   
        </>

    )
}