import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import './App.css';
import Header from './components/headers/header';
import Footer from './components/footer/footer';
import SearchCpnt from './components/search/search';

import Home from './pages/home/home';
import About from './pages/about/about';
import Login from './pages/login/login';
import Users from './pages/users/users';
import AddEmployeeData from './pages/addemployee/addemployee';
import CreateAccount from './pages/createaccount/createaccount';
import ExpertiseCat from './pages/expertisescategories/expertisescategories';
import EditExpertiseCategory from './pages/editexpertisecategory/editexpertisecategory';
import AddExpertiseCategoryData from './pages/addexpertisecategory/addexpertisecategory';
import Expertise from './pages/expertises/expertises';
import DomainDetails from './pages/domaindetails/domaindetails';
import AddDomain from './pages/adddomain/adddomain';
import LawDatatabase from './pages/lawdatabase/lawdatabase';
import LawDatataDetails from './pages/datadetails/datadetails';
import Training from './pages/training/training';
import NewsPage from './pages/news/news';
import Pleading from './pages/pleading/pleading';
import AddLawData from './pages/adddata/adddata';
import AddNews from './pages/addnews/addnews';
import AddPleading from './pages/addpleading/addpleading';
import UserProfile from './pages/userprofile/userprofile';
import ChangePassword from './pages/changepassword/changepassword';
import EditUser from './pages/edituser/edituser';
import AddActivity from './pages/addactivity/addactivity';
import TrainingDetails from './pages/trainingdata/trainingdata';
import PleadingDetails from './pages/pleadingdetails/pleadingdetails';
import NewsDetails from './pages/newsdetails/newsdetails';
import LegalKitsListPage from './pages/legalkitslist/legalkitslist';
import AddLegalKitPage from './pages/addlegalkit/addlegalkit';
import LegalKitDetails from './pages/legalkitdetails/legalkitdetails';
import EditExpertise from './pages/editexpertise/editexpertise';
import EditData from './pages/editdata/editdata';
import EditActivity from './pages/editactivity/editactivity';
import EditLegalKit from './pages/editkitdata/editkitdata';
import EditNews from './pages/editnews/editnews';
import ChatGptPage from './pages/chatgpt/chatgpt';
import Contact from './pages/contact/contact';
import FAQ from './pages/faq/faq';
import EditFAQ from './pages/editfaq/editfaq';
import RulesAndRegulations from './pages/rulesandreglementation/rulesandreglementation';
import EditRulesAndRegulations from './pages/editrulesandreglementation/editrulesandreglementation';
import Events from './pages/event/event';
import AddEvent from './pages/addevent/addevent';
import EventDetailsPage from './pages/eventdetails/eventdetails';
import EditEventPage from './pages/editevent/editevent';
import EditCompany from './pages/editcompany/editcompany';

import { useEffect, useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {

  return (
    
    <Router>
      <Header/>

      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/a-propos" element={<About />} />
        <Route path="/connection" element={<Login />} />
        <Route path="/creer-un-compte" element={<CreateAccount />} />
        <Route path="/ajouter-un-employe" element={<AddEmployeeData />} />
        <Route path="/membre" element={<Users />} />
        <Route path="/expertises" element={<ExpertiseCat />} />
        <Route path="/ajouter-category-d-expertise" element={<AddExpertiseCategoryData />} />
        <Route path="/editer-category-d-expertise" element={<EditExpertiseCategory />} />
        <Route path="/nos-domaines" element={<Expertise />} />
        <Route path="/base-de-donnees" element={<LawDatatabase />} />
        <Route path="/donnee/:id" element={<LawDatataDetails />} />
        <Route path="/ajouter-donnees" element={<AddLawData />} />
        <Route path="/activite" element={<Training />} />
        <Route path="/actualites" element={<NewsPage />} />
        <Route path="/ajouter-article" element={<AddNews />} />
        <Route path="/plaidoyer" element={<Pleading />} />
        <Route path="/activite-details" element={<TrainingDetails />} />
        <Route path="/recherche" element={<SearchCpnt />} />
        <Route path="/ajouter-domaine" element={<AddDomain />} />
        <Route path="/domain-details" element={<DomainDetails />} />
        <Route path="/ajouter-un-plaidoyer" element={<AddPleading />} />
        <Route path="/profile" element={<UserProfile />} />
        <Route path="/changer-mot-de-passe" element={<ChangePassword />} />
        <Route path="/editer-utilisateur" element={<EditUser />} />
        <Route path="/ajouter-activite" element={<AddActivity />} />
        <Route path="/plaidoyer-activite" element={<PleadingDetails />} />
        <Route path="/article-details" element={<NewsDetails />} />
        <Route path="/kit-juridique" element={<LegalKitsListPage />} />
        <Route path="/ajouter-une-kit-juridique" element={<AddLegalKitPage />} />
        <Route path="/kit-juridique-details" element={<LegalKitDetails />} />
        <Route path="/editer-expertise" element={<EditExpertise />} />
        <Route path="/editer-donnee" element={<EditData />} />
        <Route path="/editer-activiter" element={<EditActivity />} />
        <Route path="/editer-kit-juridique" element={<EditLegalKit />} />
        <Route path="/editer-article" element={<EditNews />} />
        <Route path="/chat-en-ligne" element={<ChatGptPage />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/edit-faq" element={<EditFAQ />} />
        <Route path="/politique-de-confidentialite" element={<RulesAndRegulations />} />
        <Route path="/editer-politique-de-confidentialite" element={<EditRulesAndRegulations />} />
        <Route path="/evenements" element={<Events />} />
        <Route path="/ajouter-un-evenement" element={<AddEvent />} />
        <Route path="/evenement-details" element={<EventDetailsPage />} />
        <Route path="/editer-un-evenement" element={<EditEventPage />} />
        <Route path="/editer-les-donnees-d-entreprise" element={<EditCompany />} />
        
      </Routes>

      <Footer/>
    <ToastContainer />

    </Router>

  );
}

export default App;
