import './addlegalkit.css';

import axios from 'axios';
import parse from 'html-react-parser';
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { Circles } from  'react-loader-spinner';
import CKeditor from '../../components/CKeditor';

import { expertiseDataFetchSuccess } from '../../redux/expertisesSlice';

export default function AddLegalKit() {

    const [ title, setTitle ] = useState("");
    const [ image, setUploadedFilePath ] = useState("");
    const [ description, setDescription ] = useState("");

    const [editorLoaded, setEditorLoaded] = useState(false);
    const [load, setLoader] = useState('');

    const loggedUser = useSelector(state=>state.user.user);

    const handleFileUpload = async(event) => {

        const current_file = event.target.files[0];
        const formData = new FormData();
        formData.append('file', current_file);

        try {
            const fileUploadData = await axios.post(process.env.REACT_APP_API_KEY+'/law-database/file-upload/', formData, {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            });
            setUploadedFilePath(fileUploadData.data.uploaded_file);
        } catch (error) {
            toast.error("Erreur lors du téléchargement du fichier : "+error, { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast1' });
            
        }

    };

    const expertisesSelector = useSelector(expertiseDataFetchSuccess);

    useEffect(()=>{

        setEditorLoaded(true);

    }, []);

    const postData = async(e) => {
        e.preventDefault();

        if((title == "")||(description == "")) {
            toast.warning("Veuillez remplir tout le formulaire avant de poursuivre !", { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast1' });
        }
        else
        {
            setLoader(true);

            try{

                const data_post = await axios.post(process.env.REACT_APP_API_KEY+"/legal-kit", {title, description}, {
                        headers: {
                            Authorization: loggedUser.token
                        }
                    });

                setTitle("");
                setDescription("");

                setLoader(false);

                toast.success(data_post.data.message, { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast1' });
            }
            catch(err) {
                setLoader(false);
                toast.error("Une erreur est survenue !", { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast1' });
            }

        }

    }

    return(
        
        <>
            <div className="top-section">.</div>

            {loggedUser && loggedUser.is_admin === true ? 

            	<section aria-label="section">
                    <div className="container">
                        <div className="row">

                            <div id="comment-form-wrapper">

                                <h4 className="form-title">Ajouter une kit juridique</h4>
                                <br/>

                                <div className="form-default">
                                    <label>Titre <span className="req">*</span></label>
                                    <input type="text" className="input" value={ title } onChange={ e=>setTitle(e.target.value) } />
                                    {/*<label>Fichier <span className="req">*</span></label>
                                                                        <input type="file" className="input" onChange={ handleFileUpload } />
                                                                        <br/>*/}
                                    <label>Description <span className="req">*</span></label>
                                    <textarea cols="10" rows="10" className="area-input" value={ description }  onChange={ e=>setDescription(e.target.value) } ></textarea>
                                    
                                    {/*<CKeditor
                                                                            name="description"
                                                                            onChange={(article) => {
                                                                              setArticle(article);
                                                                            }}
                                                                            editorLoaded={editorLoaded}
                                                                        />
                                                                        <br/>*/}
                                    
                                    {load === true ?
                                        <a className="posting-load">
                                            <Circles 
                                                height="40"
                                                width="40"
                                                color="#000"
                                                ariaLabel="circles-loading"
                                                wrapperStyle={{}}
                                                wrapperClass=""
                                                visible={true}
                                            />
                                        </a> : <button className="btn btn-custom" onClick={ postData }>Poster</button>   
                                        
                                    }

                                </div>

                            </div>
                            
                        </div>
                    </div>
                </section>   

            :

                <div className="kode-content">
                    <div className="kode-about-us-section">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    Vous n'êtes pas autorisé(e) à accéder à cette section !
                                </div> 
                            </div>
                        </div> 
                    </div>
                </div> 
                        
            }
        </>

    )
}