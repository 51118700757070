import './lawsrepository.css';

import axios from 'axios';
import Swal from 'sweetalert2';
import { useEffect, useState } from 'react';
import parse from 'html-react-parser';
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { TailSpin, RotatingLines } from  'react-loader-spinner';

export default function LawsRepository({ search_term, category, limit }) {

	const [repositoryData, setRepositoryData] = useState([]);
	const [law_category_data, setLawCategoryData] = useState([]);
    const [loadLawData, setLoadLawData] = useState("true");

	const loggedUser = useSelector(state=>state.user.user);

	useEffect(()=> {

		const getRepositotyData = async()=> {

			try {
				const data = await axios.get(process.env.REACT_APP_API_KEY+'/law-database/?search_term='+search_term+'&category='+category+'&limit='+limit);
				setRepositoryData(data.data);

				setLoadLawData("false");
			}
			catch(err) {
				toast.error(err.response.data, { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast1' });
			}
		}

		const getLawDataCategoryData = async()=> {

			try {
				const law_category_res = await axios.get(process.env.REACT_APP_API_KEY+'/law-categories/'+category);
				setLawCategoryData(law_category_res.data);
			}
			catch(err) {
				toast.error(err.response.data, { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast3' });
			}
		}

		// functions call

		getRepositotyData();
		getLawDataCategoryData();

	});

	const removeData = (data_id, data_name)=> {

		Swal.fire({
		  title: 'Effacer '+data_name,
		  text: "Vous ne pourrez pas revenir en arrière !",
		  icon: 'warning',
		  showCancelButton: true,
		  confirmButtonColor: '#3085d6',
		  cancelButtonColor: '#d33',
		  confirmButtonText: 'Oui, effacer !',
		  cancelButtonText: 'Non !'
		}).then((result) => {
		  if (result.isConfirmed) {

		    deleteData(data_id);

		  }
		})

	}

	const deleteData = async(data_id) => {

		toast.info("Opération en cours !", { position: toast.POSITION.TOP_RIGHT, autoClose: 5000, toastId: 'toast2' });

        try{
        	const expertise_delete_data = await axios.put(process.env.REACT_APP_API_KEY+"/law-database/delete/"+data_id, {}, {
                headers: {
                    Authorization: loggedUser.token
                }
            });

            toast.success("Donnée effacée avec succès !", { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast1' });

            this.getRepositotyData();
            
        }
        catch(err) {

        	if (err.response && err.response.data) {
		        toast.error("Une erreur est survenue : " + err.response.data.message, { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast1' });
		    }
		    
        }

    }

	return (

			<section aria-label="section" className="database-section">
	            <div className="container">
	                <div className="row">
	                    <div className="col-md-12">
	                        <div className="text-center">
	                            <h2>Base de données: {law_category_data.category} <br/><br/></h2>
	                        </div>
	                    </div>
	                </div>
	                <div className="row">

	                	{loadLawData === 'true' ? 

	                        <div className="data-loader">

	                            <TailSpin 
	                                height="40"
	                                width="40"
	                                color="#000"
	                                ariaLabel="tail-spin-loading"
	                                radius="1"
	                                wrapperStyle={{}}
	                                wrapperClass=""
	                                visible={true}
	                            />

	                        </div>

	                        : "" 
	                    }

		                {repositoryData?.map((data)=>(

		                	<div className="col-lg-4 col-md-6 mb30">
		                        <div className="bloglist item">

		                        	<div className="post-content card">
		                        		<div className="post-text white-background">

					                        {loggedUser && loggedUser.is_admin === true ? 

			                                    <>
				                                    <div className="text-lg-right text-sm-center">
				                                        <button className="danger-button" onClick={ e=>removeData(data._id, data.title) }>Effacer </button>
				                                    </div>
				                                    <div className="text-lg-right text-sm-center">
				                                        <Link to={`/editer-donnee/?param=${ data._id}`} className="clear-button">Editer </Link>
				                                    </div>
			                                    </>

			                                : '' }

		                        			{data.category ? <span className="p-tagline">{data.category.category}</span> : '' }
		                                    {data.subCategory ? <span className="p-author"><br/>{data.subCategory.subCategory}</span> : '' }
		                                    <h4><a href="#">{data.title}<span></span></a></h4>
		                                    {/*<p>{parse(data.description.substring(0, 130)+ '...')}</p>*/}
		                                    <p>{parse(data.description)}</p>
		                                    <div className="top-10"></div>
		                                    <a href={data.file} target="_blank" className="btn-custom read-more">Télécharger</a>
		                                </div>
		                            </div>
		                        </div>
		                    </div>

		                ))}

	            </div>
	        </div>
	    </section>

	)

}
