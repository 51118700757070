import './expertisedetails.css';

import axios from 'axios';
import Swal from 'sweetalert2';
import { useEffect, useState } from 'react';
import parse from 'html-react-parser';
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';

export default function ExpertiseDetailsCpnt({ domain_id }) {

	const [expertiseData, setExpertiseData] = useState([]);
    const [showNoData, setShowNoData] = useState("false");

	const loggedUser = useSelector(state=>state.user.user);

	useEffect(()=> {

		const getExpertiseData = async()=> {

			try {
				const domain_data = await axios.get(process.env.REACT_APP_API_KEY+'/expertise/'+domain_id);
				setExpertiseData(domain_data.data);
			}
			catch(err) {
				setShowNoData("true");
				toast.error(err.response.data, { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast1' });
			}
		}

		// functions call

		getExpertiseData();

	}, []);

	const removeExpertise = (expertise_id, expertise_name)=> {

		Swal.fire({
		  title: 'Effacer '+expertise_name,
		  text: "Vous ne pourrez pas revenir en arrière !",
		  icon: 'warning',
		  showCancelButton: true,
		  confirmButtonColor: '#3085d6',
		  cancelButtonColor: '#d33',
		  confirmButtonText: 'Oui, effacer !',
		  cancelButtonText: 'Non !'
		}).then((result) => {
		  if (result.isConfirmed) {

		    deleteExpertise(expertise_id);

		  }
		})

	}

	const deleteExpertise = async(expertise_id) => {

		toast.info("Opération en cours !", { position: toast.POSITION.TOP_RIGHT, autoClose: 5000, toastId: 'toast2' });

        try{
        	const expertise_delete_data = await axios.put(process.env.REACT_APP_API_KEY+"/expertise/delete/"+expertise_id, {}, {
                headers: {
                    Authorization: loggedUser.token
                }
            });

            setShowNoData("true");

            toast.success(expertise_delete_data.data.message, { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast1' });
        }
        catch(err) {
            toast.error("Une erreur est survenue : "+err.response.data.message, { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast1' });
        }

    }

	return (

		<section aria-label="section">
            <div className="container">

	            <div className="row">

	            	{loggedUser ?

                        <section className="text-light search-section">
                            <div className="container">
                                <div className="row align-items-center">

                                    { loggedUser.is_admin === true ? 

                                    	<>
	                                        <div className="col-md-2 text-lg-right text-sm-center">
	                                            <Link to={`/editer-expertise/?param=${ expertiseData._id}`} className="clear-button">Editer </Link>
	                                        </div>
	                                        <div className="col-md-2 text-lg-right text-sm-center">
	                                            <button className="danger-button" onClick={ e=>removeExpertise(expertiseData._id, expertiseData.name) }>Effacer </button>
	                                        </div>
                                        </>

                                    : '' }

                                </div>
                            </div>
                        </section>

                    : '' }

	                {/*<div className="col-md-4">
	                	{ userData.profile_image ? <img className="profile-image" src={ userData.profile_image } alt="" /> : <img src="/images/misc/avatar.png" alt="" /> }
	                </div>*/}

	                {showNoData === 'true' ? <div className="no-data">Données supprimées</div> : 

		               	<div className="col-md-12">
		                	<h2 className="c_name"> { expertiseData.name }</h2>
		                	<br/><br/>
		                    <div className="comment">{typeof expertiseData.description === 'string' ? parse(expertiseData.description) : null}</div>   
		                </div>

		            }

	            </div>

            </div>
        </section>
        
	)

}
