import './login.css';

import axios from 'axios';
import Swal from 'sweetalert2';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { Circles } from  'react-loader-spinner';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { loginFailure, loginStart, loginSuccess } from '../../redux/userSlice';

export default function Login() {

    const navigate = useNavigate();

    const loggedUser = useSelector(state=>state.user.user);

    /*if(loggedUser) {
        window.location.href = "/";
        toast.info("Vous êtes déjà connecté(e) !", { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast1' });
    }*/
    
    const [load, setLoader] = useState('');
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const dispatch = useDispatch();

    const handleLogin = async(e) => {
        e.preventDefault();

        if((email == "")||(password == "")) {
            toast.warning("Veuillez remplir tout le formulaire avant de poursuivre !", { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast1' });
        }
        else
        {
            setLoader(true);

            dispatch(loginStart());

            try{

                const loginRes = await axios.post(process.env.REACT_APP_API_KEY+"/auth", {email, password});
                dispatch(loginSuccess(loginRes.data));

                setLoader(false);

                navigate('/');

                toast.success("Connexion réussie !", { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast1' });
            }
            catch(err) {

                setLoader(false);
                dispatch(loginFailure());
                toast.error(err.response.data.message, { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast1' });
            }
        }

    }

    const recoverEmail = async () => {

      Swal.fire({
        title: 'Votre adresse e-mail',
        input: 'email',
        customClass: {
          validationMessage: 'my-validation-message'
        },
        preConfirm: (value) => {
          if (!value) {
            throw new Error('Veuillez saisir votre adresse e-mail !'); // Throw an error to reject the promise
          }
        }
      })
        .then(({ value }) => {
          requestRecovery(value); // Call requestRecovery after the Swal dialog is closed
        })
        .catch((error) => {
          // Handle the error, Swal dialog will not be closed
          toast.error(error, { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast1' });
        });

    }

    const requestRecovery = async (email) => {

        try 
        {
            const passwordRecoveryRes = await axios.get(process.env.REACT_APP_API_KEY+"/auth/recover-password/" + email);
            toast.success(passwordRecoveryRes.data.message, { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast1' });
        }
        catch (error) {
            toast.error(error.response.data.message, { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast1' });
        }
      
    }

    return(

        <>
            <div className="top-section">.</div>

            {loggedUser ? 

                <div className="kode-content">
                    <section className="kode-about-us-section">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-6 success-message">
                                    Vous êtes déjà connecté(e)
                                </div> 
                            </div>
                        </div> 
                    </section>
                </div> 
                :
                <>
                    
                    <div className="kode-content">
                        <section className="kode-about-us-section">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div  className='login-title'></div>
                                        <h2 className='login-title-text'>Connection</h2>
                                        <div className='login-box'>
                                            <input className='input' type="text" placeholder='Addresse Email' onChange={e=>setEmail(e.target.value)} />                                
                                            <input className='input' type="password" placeholder='Mot De Passe' onChange={e=>setPassword(e.target.value)} />
                                            
                                            {load === true ?
                                                <a className="posting-load">
                                                    <Circles 
                                                        height="50"
                                                        width="50"
                                                        color="#000"
                                                        ariaLabel="circles-loading"
                                                        wrapperStyle={{}}
                                                        wrapperClass=""
                                                        visible={true}
                                                    />
                                                </a> : <input className='submit-button' type="submit" value="Se Connecter" onClick={handleLogin} /> 
                                                
                                            }

                                        </div>
                                    </div>
                                    <div className="col-md-5">
                                        <div className='help-card'>
                                            <h3>Besoin d'aide?</h3>
                                            <button onClick={recoverEmail} >Récupérer mot de passe</button>
                                            <Link to="/creer-un-compte"><button>Créer un compte</button></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </>
            }
        </>
        
    )
}