import './trainingdata.css';

import axios from 'axios';
import parse from 'html-react-parser';
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { toast } from 'react-toastify';
import { Circles } from  'react-loader-spinner';
import { useSelector } from 'react-redux';

import TrainingDetailsCpnt from '../../components/trainingdetails/trainingdetails';

export default function TrainingDetails() {

    const queryParams = new URLSearchParams(window.location.search);

    const training = queryParams.get('training');

    const loggedUser = useSelector(state=>state.user.user);

    return(
        
        <>
            <div className="top-section">.</div>


            	<section aria-label="section">
                    <div className="container">
                        <div className="row">

                            <TrainingDetailsCpnt training_id={ training } />
                            
                        </div>
                    </div>
                </section>   

        </>

    )
}