import './singleuser.css';

import axios from 'axios';
import { useEffect, useState } from 'react';
import parse from 'html-react-parser';
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
import { TailSpin, RotatingLines } from  'react-loader-spinner';

export default function SingleUser({ userid }) {

	const [userData, setUserData] = useState([]);
    const [loadUsers, setLoadUsers] = useState("true");

	useEffect(()=> {

		const getUserData = async()=> {
			try {
				const user_data = await axios.get(process.env.REACT_APP_API_KEY+'/users/'+userid);
				setUserData(user_data.data);

				setLoadUsers("false");
			}
			catch(err) {
				toast.error(err.response.data, { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast1' });
			}
		}

		// functions call

		getUserData();

	}, []);

	return (

		<section aria-label="section">
            <div className="container">
                <div className="row">

                	{loadUsers === 'true' ? 

		                <div className="data-loader">

		                    <TailSpin 
		                        height="40"
		                        width="40"
		                        color="#000"
		                        ariaLabel="tail-spin-loading"
		                        radius="1"
		                        wrapperStyle={{}}
		                        wrapperClass=""
		                        visible={true}
		                    />

		                </div>

		                : "" 
		            }

                	<div className="col-md-4">
                		{ userData.profile_image ? <img className="profile-image" src={ userData.profile_image } alt="" /> : <img src="/images/misc/avatar.png" alt="" /> }
                	</div>

                	<div className="col-md-8">
                        <div className="comment-info">
                            <span className="c_name">{ userData.title } { userData.first_name } { userData.last_name }</span>

                            <div className="widget widget-post">
                                <ul>
                                    <li><span className="label">Email : </span><a href="#"> &nbsp;{ userData.email }</a></li>
                                    <li><span className="label">Tel : </span><a href="#"> &nbsp;{ userData.tel }</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="comment">{typeof userData.short_bio === 'string' ? parse(userData.short_bio) : null}</div>   
                	</div>

                </div>
            </div>
        </section>
        
	)

}
