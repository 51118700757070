import './editevent.css';

import axios from 'axios';
import parse from 'html-react-parser';
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { Circles } from  'react-loader-spinner';
import CKeditor from '../../components/CKeditor';

export default function EditEventPage() {

    const queryParams = new URLSearchParams(window.location.search);
    const param = queryParams.get('param');

    const loggedUser = useSelector(state=>state.user.user);

    const [ id, setEventId ] = useState("");
    const [ title, setTitle ] = useState("");
    const [ file, setUploadedFilePath ] = useState("");
    const [ description, setDescription ] = useState("");
    const [ date, setDate ] = useState([]);
    const [ venue, setVenue ] = useState([]);

    const [editorLoaded, setEditorLoaded] = useState(false);
    const [load, setLoader] = useState('');

    useEffect(()=> {

        setEventId(param);
        setEditorLoaded(true);

        const getEventData = async()=> {
            const event_data = await axios.get(process.env.REACT_APP_API_KEY+'/events/'+param);

            setTitle(event_data.data.title);
            setUploadedFilePath(event_data.data.file);
            setDescription(event_data.data.description);
            setDate(event_data.data.date);
            setVenue(event_data.data.venue);

        }

        // functions call

        getEventData();

    }, []);

    const handleFileUpload = async(event) => {

        const current_file = event.target.files[0];
        const formData = new FormData();
        formData.append('file', current_file);

        try {
            const fileUploadData = await axios.post(process.env.REACT_APP_API_KEY+'/law-database/file-upload/', formData, {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            });
            setUploadedFilePath(fileUploadData.data.uploaded_file);
        } catch (error) {
            toast.error("Erreur lors du téléchargement du fichier : "+error, { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast1' });
        }

    };

    const postData = async(e) => {
        e.preventDefault();

        if((title == "")||(file == "")||(description == "")||(date == "")||(venue == "")) {
            toast.warning("Veuillez remplir tout le formulaire avant de poursuivre !", { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast1' });
        }
        else
        {
            setLoader(true);

            try{

                const data_post = await axios.post(process.env.REACT_APP_API_KEY+"/events/"+param, {id, title, file, description, venue}, {
                    headers: {
                        Authorization: loggedUser.token
                    }
                });

                setTitle(data_post.data.title);
                setUploadedFilePath(data_post.data.file);
                setDescription(data_post.data.description);
                setDate(data_post.data.date);
                setVenue(data_post.data.venue);

                setLoader(false);

                toast.success("Evénement mis à jour avec succès !", { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast1' });
            }
            catch(err) {
                setLoader(false);
                toast.error("Une erreur est survenue : "+err.response.data.message, { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast1' });
            }

        }

    }

    return(
        
        <>
            <div className="top-section">.</div>

            {loggedUser ?

            	<section aria-label="section">

                    {loggedUser.slug === param || loggedUser.is_admin === true ? 

                        <div className="container">
                            <div className="row">

                                <div className="col-md-8">

                                    <h4 className="form-title">Editer </h4>
                                    <br/>

                                    <div className="form-card">
                                        <label>Titre <span className="req">*</span></label>
                                        <input type="text" className="input" value={ title } onChange={ e=>setTitle(e.target.value) } />
                                        <label>Date <span className="req">*</span></label>
                                        <input type="text" className="input" value={ date } onChange={ e=>setDate(e.target.value) } />
                                        <label>Lieu <span className="req">*</span></label>
                                        <input type="text" className="input" value={ venue } onChange={ e=>setVenue(e.target.value) } />
                                        <label>Image <span className="req">*</span></label>
                                        <input type="file" className="input" onChange={ handleFileUpload } />
                                        <br/>
                                        <label>Description <span className="req">*</span></label>
                                        {/*<textarea cols="10" rows="10" className="area-input" value={ description }  onChange={ e=>setDescription(e.target.value) } ></textarea>*/}
                                        
                                        <CKeditor
                                            name="description"
                                            onChange={(description) => {
                                              setDescription(description);
                                            }} value={description}
                                            editorLoaded={editorLoaded}
                                        />
                                        <br/>

                                        {load === true ?
                                            <a className="posting-load">
                                                <Circles 
                                                    height="50"
                                                    width="50"
                                                    color="#000"
                                                    ariaLabel="circles-loading"
                                                    wrapperStyle={{}}
                                                    wrapperClass=""
                                                    visible={true}
                                                />
                                            </a> : <button className="btn btn-custom" onClick={ postData }>Mettre à jour</button>   
                                            
                                        }

                                    </div>

                                </div>
                                
                            </div>
                        </div>

                    :

                        <div className="kode-content">
                            <div className="kode-about-us-section">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-md-6 success-message">
                                            Vous n'êtes pas connecté(e) ! Veuillez vous connecter pour acceder cette section !
                                        </div> 
                                    </div>
                                </div> 
                            </div>
                        </div> 
                        
                    }

                </section>   

            :

                <div className="kode-content">
                    <section className="kode-about-us-section">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-6 success-message">
                                    Vous n'êtes pas connecté(e) ! Veuillez vous connecter pour acceder cette section !
                                </div> 
                            </div>
                        </div> 
                    </section>
                </div> 
                
            }

        </>

    )
}