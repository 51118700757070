import './search.css';

import axios from 'axios';
import { Link } from "react-router-dom";
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import parse from 'html-react-parser';

export default function SearchCpnt() {

	let expert_search_query = '';
	let database_search_query = '';

	const queryParams = new URLSearchParams(window.location.search);

	const experts_search_term = queryParams.get('expert');
	const database_search_term = queryParams.get('base_de_donnee');

	const [experts_search, setExpertSearch] = useState('');
	const [database_search, setDatabaseSearch] = useState('');

    const [experts_list, setExpertsList] = useState([]);
    const [database_data, setDatabaseData] = useState([]);

    useEffect(()=>{

        window.scrollTo(0, 0);

        const getExpertsSearch = async()=>{

        	if(experts_search == '') {
        		expert_search_query = experts_search_term;
        	}
        	else
        	{
        		expert_search_query = experts_search;
        	}

            try{

                const expertsRes = await axios.get(process.env.REACT_APP_API_KEY+'/experts/?search=true&search_term='+expert_search_query);
                setExpertsList(expertsRes.data);

            }
            catch(err) {
                toast.error(err.response.data, { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast1' });
            }

        }

        const getDatabaseSearch = async()=>{

        	if(database_search == '') {
        		database_search_query = database_search_term;
        	}
        	else
        	{
        		database_search_query = database_search;
        	}

            try{

                const databaseRes = await axios.get(process.env.REACT_APP_API_KEY+'/law-database/?search=true&search_term='+database_search_query);
                setDatabaseData(databaseRes.data);

            }
            catch(err) {
                toast.error(err.response.data, { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast1' });
            }

        }

        // functions call

        getExpertsSearch();
        getDatabaseSearch();

    }, [experts_search, database_search]);

    
    return(

        <>
            <div className="top-section">.</div>

            <section className="pt40 pb40 text-light">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-4 mb-sm-30 text-lg-left text-sm-center">
                            <input className="search-field2" value={experts_search} onChange={e=>setExpertSearch(e.target.value)} placeholder="Dans les experts" type="text" />
                        </div>
                        <div className="col-md-4 mb-sm-30 text-lg-left text-sm-center">
                            <input className="search-field2" value={database_search} onChange={e=>setDatabaseSearch(e.target.value)} placeholder="Dans la base de données" type="text" />
                        </div>
                    </div>
                </div>
            </section>

            <div className="container search-results">
                <div className="row">

                	<div className="col-md-12">
                        <div className="widget widget-post">
                            <h4 className="search-title">Experts</h4>
                            <div className="small-border"></div>

                            {experts_list.length === 0 ? <p>Pas d'expert trouvé !</p> :
		                        <ul>
		                        	{experts_list?.map((expert)=>(
		                           		<li> <Link to="#">{expert.first_name} {expert.last_name} <i>({expert.email})</i></Link></li>
		                           	))}
			                    </ul>
	                       	}
	                        	
                        </div>
                    </div>

                    <div className="col-md-12">
                        <div className="widget widget-post">
                            <h4 className="search-title">Base de données</h4>
                            <div className="small-border"></div>

                            {database_data.length === 0 ? <p>Pas de donnée trouvée !</p> :
		                        <ul>
		                        	{database_data?.map((law)=>(
		                           		<li> <Link to="#">{law?.title}  <i>({parse(law?.description.substring(0, 50)+ '...')})</i></Link></li>
		                           	))}
			                    </ul>
	                       	}

                        </div>
                    </div>

                </div>
            </div>
        
        </>

    )
}