import './datadetails.css';

import axios from 'axios';
import parse from 'html-react-parser';
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { toast } from 'react-toastify';

import SingleData from '../../components/singledata/singledata';

export default function LawDatataDetails() {

	const queryParams = new URLSearchParams(window.location.search);

	const law_category = queryParams.get('category');

    return(
        
        <>
            <div className="top-section">.</div>
        	<section aria-label="section">
                <div className="container">
                    <div className="row">
                    
                        <SingleData category={ law_category } />

                    </div>
                </div>
            </section>   
        </>

    )
}