import './domaindetails.css';

import axios from 'axios';
import parse from 'html-react-parser';
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { toast } from 'react-toastify';
import { Circles } from  'react-loader-spinner';
import { useSelector } from 'react-redux';

import UserCpnt from '../../components/users/users';
import ExpertiseDetailsCpnt from '../../components/expertisedetails/expertisedetails';

export default function DomainDetails() {

    const queryParams = new URLSearchParams(window.location.search);

    const domain = queryParams.get('domain');
    const experts = queryParams.get('expert');

    const loggedUser = useSelector(state=>state.user.user);

    return(
        
        <>
            <div className="top-section">.</div>


            	<section aria-label="section">
                    <div className="container">
                        <div className="row">

                            <ExpertiseDetailsCpnt domain_id={domain} />

                            <h2 className="c_name expert-title">Experts</h2>
                            <br/>

                            <UserCpnt expert={experts} domain={domain} />
                            
                        </div>
                    </div>
                </section>   

        </>

    )
}