import './chatgpt.css';

import ChatGptCpnt from '../../components/chatgptcpnt/chatgptcpnt';

export default function ChatGptPage() {

    return(
        
        <>
            <div className="top-section">.</div>


                <section aria-label="section">
                    <div className="container">
                        <div className="row">

                            <ChatGptCpnt />
                            
                        </div>
                    </div>
                </section>   

        </>

    )
}