import './editexpertisecategory.css';

import axios from 'axios';
import parse from 'html-react-parser';
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { Circles } from  'react-loader-spinner';
import CKeditor from '../../components/CKeditor';

export default function EditExpertiseCategory() {

    const queryParams = new URLSearchParams(window.location.search);
    const param = queryParams.get('param');

    const loggedUser = useSelector(state=>state.user.user);

    const [ id, setExpertiseCategoryId ] = useState("");
    const [ category, setCategory ] = useState("");
    const [ short_description, setShortDescription ] = useState("");

    const [editorLoaded, setEditorLoaded] = useState(false);
    const [load, setLoader] = useState('');

    useEffect(()=> {

        setExpertiseCategoryId(param);
        setEditorLoaded(true);

        const getExpertiseData = async()=> {
            const expertise_data = await axios.get(process.env.REACT_APP_API_KEY+'/expertise-categories/'+param);

            setCategory(expertise_data.data.category);
            setShortDescription(expertise_data.data.short_description);
        }

        // functions call

        getExpertiseData();

    }, []);

    const postData = async(e) => {
        e.preventDefault();

        if((category == "")||(short_description == "")) {
            toast.warning("Veuillez remplir tout le formulaire avant de poursuivre !", { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast1' });
        }
        else
        {
            setLoader(true);

            try{

                const data_post = await axios.post(process.env.REACT_APP_API_KEY+"/expertise-categories/"+param, {id, category, short_description}, {
                    headers: {
                        Authorization: loggedUser.token
                    }
                });

                setCategory(data_post.data.category);
                setShortDescription(data_post.data.short_description);

                setLoader(false);

                toast.success("Catégorie de domaine mis à jour avec succès !", { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast1' });
            }
            catch(err) {
                setLoader(false);
                toast.error("Une erreur est survenue : "+err.response.data.message, { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast1' });
            }

        }

    }

    return(
        
        <>
            <div className="top-section">.</div>

            {loggedUser ?

            	<section aria-label="section">

                    {loggedUser.slug === param || loggedUser.is_admin === true ? 

                        <div className="container">
                            <div className="row">

                                <div className="col-md-8">

                                    <h4 className="form-title">Editer </h4>
                                    <br/>

                                    <div className="form-card">
                                        <label>Catégorie <span className="req">*</span></label>
                                        <input type="text" className="input" value={ category } onChange={ e=>setCategory(e.target.value) } />
                                        <label>Brève description <span className="req">*</span></label>
                                        <textarea cols="10" rows="10" className="area-input" value={ short_description }  onChange={ e=>setShortDescription(e.target.value) } ></textarea>
                                        
                                        {/*<CKeditor
                                                                                    name="description"
                                                                                    onChange={(description) => {
                                                                                      setDescription(description);
                                                                                    }} value={description}
                                                                                    editorLoaded={editorLoaded}
                                                                                />
                                                                                <br/>*/}

                                        {load === true ?
                                            <a className="posting-load">
                                                <Circles 
                                                    height="50"
                                                    width="50"
                                                    color="#000"
                                                    ariaLabel="circles-loading"
                                                    wrapperStyle={{}}
                                                    wrapperClass=""
                                                    visible={true}
                                                />
                                            </a> : <button className="btn btn-custom" onClick={ postData }>Mettre à jour</button>   
                                            
                                        }

                                    </div>

                                </div>
                                
                            </div>
                        </div>

                    :

                        <div className="kode-content">
                            <div className="kode-about-us-section">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-md-6 success-message">
                                            Vous n'êtes pas connecté(e) ! Veuillez vous connecter pour acceder cette section !
                                        </div> 
                                    </div>
                                </div> 
                            </div>
                        </div> 
                        
                    }

                </section>   

            :

                <div className="kode-content">
                    <section className="kode-about-us-section">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-6 success-message">
                                    Vous n'êtes pas connecté(e) ! Veuillez vous connecter pour acceder cette section !
                                </div> 
                            </div>
                        </div> 
                    </section>
                </div> 
                
            }

        </>

    )
}