import './expertisescategories.css';

import axios from 'axios';
import parse from 'html-react-parser';
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { toast } from 'react-toastify';
import { LineWave } from  'react-loader-spinner';
import { useSelector } from 'react-redux';

import ExpertiseCatCpnt from '../../components/expertisescategoriescpnt/expertisescategoriescpnt';

export default function ExpertiseCat() {

    const loggedUser = useSelector(state=>state.user.user);

    return(
        
        <>
            <div className="top-section">.</div>

            {/*{loggedUser && loggedUser.is_admin === true ? 

                <section className="pt40 pb40 text-light">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-8 mb-sm-30 text-lg-left text-sm-center">
                            </div>
                            <div className="col-md-4 text-lg-right text-sm-center">
                                <Link to="/ajouter-category-d-expertise" className="add-database">Ajouter une catégorie de domaine</Link>
                            </div>
                        </div>
                    </div>
                </section>

            : "" }*/}

        	<section aria-label="section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h2><br/>Catégories de domaines <br/><br/></h2>
                        </div>

                        <div className="col-md-12">

                            <ExpertiseCatCpnt />

                        </div>

                    </div>
                </div>
            </section>   
        </>

    )
}