import './rulesandreglementation.css';

import axios from 'axios';
import Swal from 'sweetalert2';
import { useEffect, useState } from 'react';
import parse from 'html-react-parser';
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { TailSpin, RotatingLines } from  'react-loader-spinner';

export default function RulesAndRegulations() {

    const [rulesAndRegulationsData, setRulesAndRegulationsData] = useState([]);
    const [showNoData, setShowNoData] = useState("false");
    const [loadRulesAndRegulations, setRulesAndRegulationsLoad] = useState("true");

    const loggedUser = useSelector(state=>state.user.user);

    useEffect(()=> {

        window.scrollTo(0, 0);

        const getRulesAndRegulationsData = async()=> {

            try {
                const rules_and_regulations_data = await axios.get(process.env.REACT_APP_API_KEY+'/rules-and-regulations/');
                setRulesAndRegulationsData(rules_and_regulations_data.data);

                setRulesAndRegulationsLoad("false");
            }
            catch(err) {
                setRulesAndRegulationsLoad("false");
                setShowNoData("true");
                toast.error(err.response.data, { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast1' });
            }
        }

        // functions call

        getRulesAndRegulationsData();

    }, []);

    return (

            <>

            <div className="top-section">.</div>

            <section aria-label="section">
                <div className="container">

                    <div className="row">

                        {loggedUser ?

                            <section className="text-light search-section">
                                <div className="container">
                                    <div className="row align-items-center">

                                        { loggedUser.is_admin === true ? 

                                            <>
                                                {rulesAndRegulationsData?.map((rule)=>(
                                                    <div className="col-md-2 text-lg-right text-sm-center">
                                                        <Link to={`/editer-politique-de-confidentialite/?param=${ rule._id}`} className="clear-button">Editer </Link>
                                                    </div>
                                                ))}
                                            </>

                                        : '' }

                                    </div>
                                </div>
                            </section>

                        : '' }

                        {/*<div className="col-md-4">
                            { userData.profile_image ? <img className="profile-image" src={ userData.profile_image } alt="" /> : <img src="/images/misc/avatar.png" alt="" /> }
                        </div>*/}

                        {showNoData === 'true' ? <div className="no-data">Données supprimées</div> : 

                            <div className="col-md-12">

                                {loadRulesAndRegulations === 'true' ? 

                                    <div className="data-loader">

                                        <TailSpin 
                                            height="40"
                                            width="40"
                                            color="#000"
                                            ariaLabel="tail-spin-loading"
                                            radius="1"
                                            wrapperStyle={{}}
                                            wrapperClass=""
                                            visible={true}
                                        />

                                    </div>

                                    : "" 
                                } 

                                {rulesAndRegulationsData?.map((rule)=>(

                                    <>
                                        <h2 className="c_name"> Politique de confidentialité </h2>
                                        <br/><br/>
                                        <div className="comment">{typeof rule.rules === 'string' ? parse(rule.rules) : null}</div>
                                    </>

                                ))}

                            </div>

                        }

                    </div>

                </div>
            </section>
        </>
        
    )

}
