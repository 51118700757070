import './editactivity.css';

import axios from 'axios';
import parse from 'html-react-parser';
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { Circles } from  'react-loader-spinner';
import CKeditor from '../../components/CKeditor';

import { expertiseDataFetchSuccess } from '../../redux/expertisesSlice';

export default function EditActivity() {

    const queryParams = new URLSearchParams(window.location.search);
    const param = queryParams.get('param');

    const loggedUser = useSelector(state=>state.user.user);

    const [ id, setUserId ] = useState("");
    const [ is_sensibilization, setSensibilization ] = useState("");
    const [ is_presential, setPresential ] = useState("");
    const [ title, setTitle ] = useState("");
    const [ short_description, setShortDescription ] = useState([]);
    const [ content, setContent ] = useState([]);
    const [ file, setUploadedFilePath ] = useState("");
    const [ teacher, setTeacher ] = useState("");
    const [ current_teacher, setCurrentTeacher ] = useState([]);
    const [ teachers_list, setTeachersList ] = useState([]);

    const [editorLoaded, setEditorLoaded] = useState(false);
    const [load, setLoader] = useState('');

    useEffect(()=> {

        setUserId(param);
        setEditorLoaded(true);

        const getActivityData = async()=> {
            const activity_data = await axios.get(process.env.REACT_APP_API_KEY+'/course/'+param);

            setTitle(activity_data.data.title);
            setPresential(activity_data.data.is_presential);
            setUploadedFilePath(activity_data.data.file);
            setTeacher(activity_data.data.teacher._id);
            setCurrentTeacher(activity_data.data.teacher);
            setShortDescription(activity_data.data.short_description);
            setContent(activity_data.data.content);
        }

        const getTeachersList = async()=> {

            try{
                const teachersListRes = await axios.get(process.env.REACT_APP_API_KEY+"/users/?is_trainer=true");
                setTeachersList(teachersListRes.data);
            }
            catch(err) {
                toast.error("Erreur de serveur : les formateurs n'ont pas été récupérés !", { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast1' });
            }

        }

        // functions call

        getActivityData();
        getTeachersList();

    }, []);

    const handleFileUpload = async(event) => {

        const current_file = event.target.files[0];
        const formData = new FormData();
        formData.append('file', current_file);

        try {
            const fileUploadData = await axios.post(process.env.REACT_APP_API_KEY+'/law-database/file-upload/', formData, {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            });
            setUploadedFilePath(fileUploadData.data.uploaded_file);
        } catch (error) {
            toast.error("Erreur lors du téléchargement du fichier : "+error, { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast1' });
        }

    };

    const postData = async(e) => {
        e.preventDefault();

        if((title == "")||(short_description == "")||(content == "")||(file == "")||(teacher == "")) {
            toast.warning("Veuillez remplir tout le formulaire avant de poursuivre !", { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast1' });
        }
        else
        {
            setLoader(true);

            try{

                const data_post = await axios.post(process.env.REACT_APP_API_KEY+"/course/"+param, {id, is_presential, title, short_description, content, file, teacher}, {
                    headers: {
                        Authorization: loggedUser.token
                    }
                });

                setTitle(data_post.data.title);
                setPresential(data_post.data.is_presential);
                setUploadedFilePath(data_post.data.file);
                setTeacher(data_post.data.teacher._id);
                setCurrentTeacher(data_post.data.teacher);
                setShortDescription(data_post.data.short_description);
                setContent(data_post.data.content);

                setLoader(false);

                toast.success("Activité mis à jour avec succès !", { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast1' });
            }
            catch(err) {
                setLoader(false);
                toast.error("Une erreur est survenue : "+err.response.data.message, { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast1' });
            }

        }

    }

    return(
        
        <>
            <div className="top-section">.</div>

            {loggedUser ?

            	<section aria-label="section">

                    {loggedUser.slug === param || loggedUser.is_admin === true ? 

                        <div className="container">
                            <div className="row">

                                <div className="col-md-8">

                                    <h4 className="form-title">Editer </h4>
                                    <br/>


                                    <div className="form-card">
                                        <label>Titre <span className="req">*</span></label>
                                        <input type="text" className="input" value={ title } onChange={ e=>setTitle(e.target.value) } />
                                        <label>Formateur(trice) <span className="req">*</span></label>
                                        <select className="input" onChange={ e=>setTeacher(e.target.value) } >
                                            <option value={ current_teacher._id }> { current_teacher.first_name } { current_teacher.last_name } </option>

                                            {teachers_list?.map((trainer)=>(
                                                <option value={ trainer._id }> { trainer.first_name } { trainer.last_name } </option>
                                            ))}

                                        </select>
                                        {is_sensibilization === true ? '' : 
                                            <>
                                                <label>Type de formation <span className="req">*</span></label>
                                                <select className="input" onChange={ e=>setPresential(e.target.value) } >
                                                    {is_presential === true ? 
                                                        <option value="true"> En Présentiel</option> 
                                                    : 
                                                        <option value="false"> En Distentiel</option> 
                                                    }
                                                    <option value="true"> En Présentiel</option>
                                                    <option value="false"> En Distentiel</option>
                                                </select>
                                            </>

                                        }
                                        <label>Fichier </label>
                                        <input type="file" className="input" onChange={ handleFileUpload } />
                                        <br/>
                                        <label>Brève description <span className="req">*</span></label>
                                        <textarea cols="10" rows="4" className="area-input" value={ short_description }  onChange={ e=>setShortDescription(e.target.value) } ></textarea>
                                        <br/>
                                        <label>Contenu <span className="req">*</span></label>
                                        {/*<textarea cols="10" rows="10" className="area-input" value={ content }  onChange={ e=>setContent(e.target.value) } ></textarea>*/}
                                        
                                        <CKeditor
                                            name="content"
                                            onChange={(content) => {
                                              setContent(content);
                                            }} value={content}
                                            editorLoaded={editorLoaded}
                                        />
                                        <br/>

                                        {load === true ?
                                            <a className="posting-load">
                                                <Circles 
                                                    height="50"
                                                    width="50"
                                                    color="#000"
                                                    ariaLabel="circles-loading"
                                                    wrapperStyle={{}}
                                                    wrapperClass=""
                                                    visible={true}
                                                />
                                            </a> : <button className="btn btn-custom" onClick={ postData }>Mettre à jour</button>   
                                            
                                        }

                                    </div>

                                </div>
                                
                            </div>
                        </div>

                    :

                        <div className="kode-content">
                            <div className="kode-about-us-section">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-md-6 success-message">
                                            Vous n'êtes pas connecté(e) ! Veuillez vous connecter pour acceder cette section !
                                        </div> 
                                    </div>
                                </div> 
                            </div>
                        </div> 
                        
                    }

                </section>   

            :

                <div className="kode-content">
                    <section className="kode-about-us-section">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-6 success-message">
                                    Vous n'êtes pas connecté(e) ! Veuillez vous connecter pour acceder cette section !
                                </div> 
                            </div>
                        </div> 
                    </section>
                </div> 
                
            }

        </>

    )
}