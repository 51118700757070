import './adddomain.css';

import axios from 'axios';
import parse from 'html-react-parser';
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { toast } from 'react-toastify';
import { Circles } from  'react-loader-spinner';
import { useSelector } from 'react-redux';
import CKeditor from '../../components/CKeditor';

export default function AddDomain() {

    const [ category, setCategory ] = useState("");
    const [ name, setName ] = useState("");
    const [ description, setDescription ] = useState("");

    const [editorLoaded, setEditorLoaded] = useState(false);
    const [load, setLoader] = useState('');

    const loggedUser = useSelector(state=>state.user.user);

    useEffect(()=>{

        setEditorLoaded(true);

    }, []);

    const postData = async(e) => {
        e.preventDefault();

        if((name == "")||(description == "")) {
            toast.warning("Veuillez remplir tout le formulaire avant de poursuivre !", { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast1' });
        }
        else
        {
            setLoader(true);

            try{

                const data_post = await axios.post(process.env.REACT_APP_API_KEY+"/expertise", {name, description}, {
                        headers: {
                            Authorization: loggedUser.token
                        }
                    });

                setName("");
                setDescription("");

                setLoader(false);

                toast.success(data_post.data.message, { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast1' });
            }
            catch(err) {
                setLoader(false);
                toast.error("Une erreur est survenue !", { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast1' });
            }

        }

    }

    return(
        
        <>
            <div className="top-section">.</div>

            {loggedUser && loggedUser.is_admin === true ? 

            	<section aria-label="section">
                    <div className="container">
                        <div className="row">

                            <div id="comment-form-wrapper">

                                <h4 className="form-title">Ajouter un domaine</h4>
                                <br/>

                                <div className="form-default">
                                    <label>Titre <span className="req">*</span></label>
                                    <input type="text" className="input" value={ name } onChange={ e=>setName(e.target.value) } />
                                    <label>Article <span className="req">*</span></label>
                                    {/*<textarea cols="10" rows="10" className="area-input" value={ description }  onChange={ e=>setDescription(e.target.value) } ></textarea>*/}

                                    <CKeditor
                                        name="description"
                                        onChange={(description) => {
                                          setDescription(description);
                                        }}
                                        editorLoaded={editorLoaded}
                                    />
                                    <br/>
                                    
                                    {load === true ?
                                        <a className="posting-load">
                                            <Circles 
                                                height="40"
                                                width="40"
                                                color="#000"
                                                ariaLabel="circles-loading"
                                                wrapperStyle={{}}
                                                wrapperClass=""
                                                visible={true}
                                            />
                                        </a> : <button className="btn btn-custom" onClick={ postData }>Poster</button>   
                                        
                                    }

                                </div>

                            </div>
                            
                        </div>
                    </div>
                </section>   

            :

                <div className="kode-content">
                    <div className="kode-about-us-section">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    Vous n'êtes pas autorisé(e) à accéder à cette section !
                                </div> 
                            </div>
                        </div> 
                    </div>
                </div> 
                        
            }
        </>

    )
}